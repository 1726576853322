/*
// .topbar-link
*/
@import "../variables";

.topbar-link {
    color: $topbar-link-color;
    transition: color 0.3s;
    width: 100%;

    &:hover {
        color: $topbar-link-hover-color;
    }
}
