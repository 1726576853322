/*
// base
*/
@import "../variables";
@import "../mixins/direction";

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: $body-font-family !important;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    background: $body-bg;
    color: $body-font-color;
    backface-visibility: hidden;
    overflow-y: scroll;

    @include direction {
        text-align: $inline-start;
        direction: $direction;
    }
}

#root {
    height: 100%;
}

svg {
    display: block;
}

a {
    color: $link-color;
}
a:hover {
    color: $link-hover-color;
    text-decoration: none;
}

label {
    margin-bottom: 0.25rem;
}

h1 {
    @if ($h1-font-family != null) {
        font-family: $h1-font-family;
    }
    @if ($h1-font-size != null) {
        font-size: $h1-font-size;
    }
    @if ($h1-font-weight != null) {
        font-weight: $h1-font-weight;
    }
    @if ($h1-font-style != null) {
        font-style: $h1-font-style;
    }
}
h2 {
    @if ($h2-font-family != null) {
        font-family: $h2-font-family;
    }
    @if ($h2-font-size != null) {
        font-size: $h2-font-size;
    }
    @if ($h2-font-weight != null) {
        font-weight: $h2-font-weight;
    }
    @if ($h2-font-style != null) {
        font-style: $h2-font-style;
    }
}
h3 {
    @if ($h3-font-family != null) {
        font-family: $h3-font-family;
    }
    @if ($h3-font-size != null) {
        font-size: $h3-font-size;
    }
    @if ($h3-font-weight != null) {
        font-weight: $h3-font-weight;
    }
    @if ($h3-font-style != null) {
        font-style: $h3-font-style;
    }
}
h4 {
    @if ($h4-font-family != null) {
        font-family: $h4-font-family;
    }
    @if ($h4-font-size != null) {
        font-size: $h4-font-size;
    }
    @if ($h4-font-weight != null) {
        font-weight: $h4-font-weight;
    }
    @if ($h4-font-style != null) {
        font-style: $h4-font-style;
    }
}
h5 {
    @if ($h5-font-family != null) {
        font-family: $h5-font-family;
    }
    @if ($h5-font-size != null) {
        font-size: $h5-font-size;
    }
    @if ($h5-font-weight != null) {
        font-weight: $h5-font-weight;
    }
    @if ($h5-font-style != null) {
        font-style: $h5-font-style;
    }
}
h6 {
    @if ($h6-font-family != null) {
        font-family: $h6-font-family;
    }
    @if ($h6-font-size != null) {
        font-size: $h6-font-size;
    }
    @if ($h6-font-weight != null) {
        font-weight: $h6-font-weight;
    }
    @if ($h6-font-style != null) {
        font-style: $h6-font-style;
    }
}
.forgrt_text {
    flex-direction: row;
    display: flex;
    margin-left: 30%;
}
.ft-password {
    padding-top: 18px;
    padding-left: 23px;
    font-weight: 500;
    font-size: 14px;
}
.txt-colr {
    color: #323232 !important;
}
.txt-justify {
    text-align: justify;
}
.pd-right {
    margin-right: 14% !important;
}
.pd-right1 {
    margin-right: 27% !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #ff0000 !important;
}
.css-1aquho2-MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #ff0000 !important;
}
.serv-bg-color {
    background-color: rgb(255, 255, 255);
    border-radius: 7px;
    margin-bottom: -2%;
}
.mt-top {
    margin-top: 4%;
}
.hight-100 {
    height: 650px;
}
.width-100 {
    width: 100%;
}
.width-50 {
    width: 50%;
    box-shadow: 0px 0px 15px 5px rgb(0 0 0 / 27%);
    border-radius: 32px;
}
.play-store-txt {
    text-align: center;
    line-height: 48px;
    margin-top: 14%;
    // margin-left: -15%;
}
.border-none {
    border: none !important;
}
.txt-space {
    word-spacing: -0.4px;
}
.popular-category-img {
    width: 100% !important;
    height: 100% !important;
}
.popular-category-m-img {
    width: 271px !important;
}
.topbar-main-txt {
    font-size: 16px;
    font-weight: 500;
}
.ab-main-txt-style {
    text-align: center;
    display: inherit;
    font-size: 119px;
    margin-top: 30px;
    color: #00e6e8;
}
.ab-main-txt-style:hover {
    color: #c60800;
}
.service-tab-txt {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.rotate:hover {
    animation: tada 1.5s ease-in-out infinite;

    @keyframes tada {
        0% {
            transform: scale(1);
        }
        10%,
        20% {
            transform: scale(0.9) rotate(-3deg);
        }
        30%,
        50%,
        70%,
        90% {
            transform: scale(1.1) rotate(3deg);
        }
        40%,
        60%,
        80% {
            transform: scale(1.1) rotate(-3deg);
        }
        100% {
            transform: scale(1) rotate(0);
        }
    }
}
img.react-photo-album--photo {
    border: 4px solid #c6080000;

    border-radius: 7px;
}
img.react-photo-album--photo:hover {
    border: 4px solid #c60800;
    animation: pulse 1s cubic-bezier(0.24, 0.8, 0.39, 1.02) infinite;

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.03);
        }
        100% {
            transform: scale(1);
        }
    }
}
.gallery-img {
    margin: 0px 46px;
    display: revert;
}
.nav-menubar-style {
    font-size: 21px;
    color: red;
    background-color: white;
    padding: 10px;
    border-radius: 36px;
}
.nav-menubar-style:hover {
    font-size: 21px;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0);
    padding: 10px;
    border-radius: 36px;
    border: 3px solid #fff;
}
.main-dropdown-btn-menu {
    color: #f70000;
}
.nav-menubar-inustry-icon {
    font-size: 42px !important;
    color: white;
    background-color: #ffffff00;
    padding: 5px;
    border-radius: 36px;
    border: 3px solid #fff;
}
.nav-menubar-inustry-icon:hover {
    font-size: 42px !important;
    color: rgb(255, 0, 0);
    background-color: #ffffff;
    padding: 5px;
    border-radius: 36px;
    // border: 3px solid rgb(0, 0, 0);
}
.nav-menubar-account-icon {
    font-size: 42px !important;
    // color: white;
    // background-color: #ffffff00;
    padding: 5px;
    border-radius: 36px;
    // border: 3px solid #fff;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    width: 15%;
}
.home-slide-carousel-style {
    height: 555px;
    margin-top: -54px;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-weight: 600 !important;
    color: #383838 !important;
}
.active {
    color: #fd0000 !important;
}
.drawer-logo {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
}
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
    font-weight: 600;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: #c60800;
    color: #ffffff;
}
.carousel-inner {
    height: 572px;
}
.service-tab-style {
    /* border: 3px solid #c60800; */
    border-radius: 9px !important;
    box-shadow: 0px 15px 14px -5px #ff000030;
}

.zoom-effect:hover {
    color: #c60800;
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.card-service-tab {
    color: rgb(22, 22, 22);
}
.spinning:hover {
    box-shadow: 0px 15px 14px -5px #2b2b2b30;
    transform: rotateY(360deg);
    animation: spin-y-axis 1s linear infinite;

    @keyframes spin-y-axis {
        0% {
            transform: rotateY(0deg);
        }
        100% {
            transform: rotateY(360deg);
        }
    }
}
.border-styles:hover {
    border: 2px solid #c60800 !important;
}
.MuiBox-root.css-w8dmq8 {
    font-family: "Catamaran", sans-serif;
    font-size: 16px;
}
.mrbt {
    margin-bottom: 2.5rem !important;
}
.ab-d-flex {
    display: flex;
}
.menu-veg-non {
    width: 10%;
}
.amount-txt {
    display: flex;
    margin-left: 10%;
    font-size: 21px;
    font-weight: 600;
    font-family: initial;
}
.package-counter {
    margin-left: 36%;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-top: 22px;
}
.display-flex {
    display: flex;
    align-items: center;
}
.mt-25 {
    margin-top: 25px;
}
.menuitem-list-style12 {
    min-width: 22rem;
}
.wd-80 {
    width: 85%;
}
.pt-lf-14 {
    padding-left: 25px;
}
.count-brt {
    border: 2px solid red;
    border-radius: 38px;
    // padding: 0px 17px;
    width: 35%;
}
.count-icon-style {
    font-size: 25px;
    color: #dc0000;
}
.count-input-value {
    width: 50%;
    border: none;
    font-weight: 600;
    text-align: center;
}
.counter-input {
    font-family: antic;
    font-weight: 700;
    display: flex;
    padding: 7px 0px;
}
.ord-btn-menu {
    width: 30%;
    color: #ffffff !important;
    background-color: #dc0000 !important;
    border-radius: 33px !important;
    padding: 10px 0px !important;
    font-size: 16px !important;
    align-items: center !important;
    border: 2px solid #e1e1e100 !important;

    &:hover {
        width: 30%;
        color: #dc0000 !important;
        background-color: #dc000000 !important;
        border-radius: 33px !important;
        padding: 10px 0px !important;
        font-size: 16px !important;
        align-items: center !important;
        border: 2px solid #dc0000 !important;
    }
}
.count-ord-btn {
    text-align: center !important;
}
.block.block-banner.products-list__item {
    border: 1px solid black !important;
}
.order-list-mu-style {
    display: flex !important;
    flex-direction: column !important;
    margin-bottom: 25px;
    padding-bottom: 14px;
    border-radius: 10px !important;
    box-shadow: 0px 8px 2px 0px rgba(255, 247, 0, 0.336);
}
.delivery-list-mu-style {
    display: flex !important;
    flex-direction: column !important;
    margin-bottom: 25px;
    padding-bottom: 14px;
    border-radius: 10px !important;
    box-shadow: 0px 8px 2px 0px rgba(37, 223, 0, 0.336);
}
.cancel-list-mu-style {
    display: flex !important;
    flex-direction: column !important;
    margin-bottom: 25px;
    padding-bottom: 14px;
    border-radius: 10px !important;
    box-shadow: 0px 8px 2px 0px rgba(255, 0, 0, 0.336);
}

.order-details-style {
    padding-left: 45px;
    color: #2a2a2a;
    font-size: 19px;
    font-weight: 700;
}
.order-status-venu {
    margin-top: 12%;
    color: #2a2a2a !important;
    font-size: 20px !important;
}
.mrg-style {
    margin-bottom: 45px;
    margin-top: 22px;
}
.order-details-1 {
    display: flex;
    margin-left: 10%;
    font-size: 21px;
    font-weight: 600;
}
.order-details-header {
    margin-top: 4%;
    background-color: #c60800;
    color: white;
    padding: 28px 0px;
    border-radius: 0px 0px 15px 15px;
    margin-top: 0px;
}
.btstyle {
    padding: 0px 0px !important;
}
.inline-flex-st {
    display: inline-flex;
    width: 100%;
}
.details-btn-pending {
    border: none !important;
    padding: 7px 27px !important;
    background-color: yellow !important;
    font-weight: 600 !important;
    width: 35% !important;
    color: rgb(32, 32, 32) !important;
}
.details-btn-pending1 {
    border: none !important;
    padding: 7px 27px !important;
    background-color: yellow !important;
    font-weight: 600 !important;
    width: 50% !important;
    color: rgb(32, 32, 32) !important;
}
.details-btn-done {
    border: none !important;
    padding: 7px 27px !important;
    background-color: #1fbf02 !important;
    font-weight: 600 !important;
    width: 75% !important;
    color: white !important;
    border-radius: 7px !important;
}
.details-btn-notdone {
    border: none;
    padding: 7px 27px;
    background-color: #818181 !important;
    font-weight: 600;
    width: 75%;
    color: rgb(0, 0, 0) !important;
    border-radius: 7px;
}
.order-status-steps {
    width: "100% ";
    height: "100%";
}
.detail-ft-size {
    font-size: 18px;
    font-weight: 600;
    padding-left: 25px;
}
.dash-line {
    border-bottom: 2px dashed #686868;
    padding: 10px 1px;
}
.veg-details-img {
    width: 2%;
    height: 2%;
    margin-right: 14px;
}
.food-item-details {
    text-align: initial;
    padding-left: 3%;
    font-size: 16px;
    font-weight: 500;
}
.detail-popup-logo {
    align-items: center;
    display: inline-flex;
    margin-left: 28%;
    width: 40%;
}
.delivered-detail-btn {
    border: none !important;
    padding: 7px 27px !important;
    background-color: #028d00 !important;
    font-weight: 600 !important;
    width: 35% !important;
    color: #ffffff !important;
}
.covid-delivered-detail-btn {
    border: none !important;
    padding: 7px 27px !important;
    background-color: #e9cd05 !important;
    font-weight: 600 !important;
    width: 35% !important;
    color: #ffffff !important;
}
.dc-view-btn {
    border: none !important;
    padding: 7px 27px !important;
    background-color: #c20e01e7 !important;
    font-weight: 600 !important;
    width: 35% !important;
    color: #ffffff !important;
}
.safety-check-popup {
    margin-top: 10%;
    border-radius: 10px;
    color: #262626;
    font-size: 20px;
    // margin-left: -35px;
}
.safety-check-popup-btn {
    border: none !important;
    padding: 7px 27px !important;
    background-color: #c20e01e7 !important;
    font-weight: 600 !important;
    width: 27% !important;
    color: #ffffff !important;
    border-radius: 24px !important;
}
.safety-check-popup .modal-content {
    border-radius: 10px;

    &:hover {
        box-shadow: 0px 1px 11px 9px rgba(255, 0, 0, 0.16);
    }
}
.cancel-tab-lenth {
    width: 50%;
}
.cancel-order-reason-tit {
    align-items: center;
    display: inline-flex;
    margin-left: 19%;
    color: #fff;
}
.txt-align-center {
    text-align: center;
}
.form-control.cancel-order-reason-input {
    width: 100%;
    border: none;
    // padding: 21px;
    border-bottom: 2px solid #4444449c;
    font-weight: 600;
    font-size: 20px;
}

.form-control.menu-change-reason-input {
    width: 100%;
    /* border: none; */
    /* border: 2px solid #4444449c; */
    font-weight: 600;
    font-size: 18px;
    border-radius: 11px;
    padding: 23px;
}
.weight-btn {
    background-color: #000000 !important;
    color: white !important;
}
.order-add-btn {
    background-color: black !important;
    color: white !important;
}
.bg-color-red {
    background-color: #c60800 !important;
}
.bg-txt-color {
    color: #fff !important;
}
.sporder-tit-txt {
    text-align: center !important;
    margin-top: 28px !important;
    font-size: 29px !important;
    margin-bottom: 37px !important;
}
.event-add-form {
    /* margin-bottom: 6%; */
    /* margin-left: 25%; */
    margin-top: 6%;
    align-items: center;
    /* position: absolute; */
    justify-content: center;
}
.event-add-form-bg {
    background-image: url("https://i.pinimg.com/736x/23/75/72/237572284635fd45ef5dc45b8b7559e5.jpg");
    background-size: cover;
    // filter: blur(8px);
    // -webkit-filter: blur(8px);
}
.menu-details-view-btn {
    border: none !important;
    padding: 7px 27px !important;
    background-color: #1b8501 !important;
    font-weight: 600 !important;
    width: 100% !important;
    color: #ffffff !important;
}
.special-menu-details-view-btn {
    border: none !important;
    padding: 7px 27px !important;
    background-color: #1b8501 !important;
    /* font-weight: 600 !important; */
    /* width: 100% !important; */
    color: #ffffff !important;
    border-radius: 7px;
    /* align-items: center; */
    /* display: contents; */
    margin-top: 35% !important;
}
.daterangepicker.ltr.show-calendar.opensright {
    color: #505050;
    font-size: 15px;
    font-weight: 600;
    font-family: math;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #357ebd;
    border-color: transparent;
    color: #fff !important;
}
.success-mbd-icon {
    margin-right: 25px;
    font-size: 31px;
    color: #00970c;
    padding-left: 12%;
}
.sporder-confirm-btn-style {
    border: none !important;
    padding: 7px 27px !important;
    background-color: #c20e01e7 !important;
    font-weight: 600 !important;
    width: 27% !important;
    color: #ffffff !important;
    border-radius: 24px !important;
    /* align-items: center !important; */
    margin-left: 38% !important;
    margin-bottom: 5% !important;
}
a.sc-bqWxrE.eCmSwl.rsc-float-button {
    background: #dc0000;
}
.sc-ipEyDJ.ipKCCz.rsc-header {
    background: #dc0000;
}
.count-date-style {
    margin: 27px 0px;
    font-size: 22px;
    color: #646464;
}

.sporder-txt {
    font-weight: 600;
    color: #979797;
    margin-top: 14px;
}

.star-rating {
    font-size: 2rem;
    cursor: pointer;
}

.filled-star {
    color: #fadb14;
}

.empty-star {
    color: #9e9e9e;
}

.filled-star:hover,
.empty-star:hover {
    color: #ffdd57;
}
.rating-btn {
    background-color: #ff0000 !important;
    color: #ffffff !important;
    width: auto !important;
}
//Home
.home-nav-panel {
    height: 54px;
    background: #00000000;
    box-shadow: 0 1px rgb(0 0 0 / 4%), inset 0 -1px rgb(0 0 0 / 4%);
    color: #ffffff;
    position: fixed;
    width: 100%;
    z-index: 1;
}
.react-3d-carousel
    .slider-container
    .slider-content
    .slider-single
    .slider-single-content {
    box-shadow: 0px 10px 40px rgb(0 0 0 / 0%) !important;
}

li.alice-carousel__stage-item {
    width: 400px !important;
}

li.alice-carousel__stage-item.__active.__target {
    margin-top: 10% !important;
}
.mrlft23 {
    margin-left: 23%;
}
.selective-option-style {
    width: 80%;
    display: inline-flex;
    flex-direction: column;
    border: 2px solid #cdcdcde8;
    border-radius: 11px;
    padding: 2px;
    text-align: initial;
}
.selective-option-style .css-13cymwt-control {
    border-color: hsla(0, 0%, 80%, 0.048) !important;
}
.txt-align-intial {
    text-align: initial !important;
}

.addinfotxt {
    width: 80%;
    display: inline-block;
    border: 1px solid #d5d5d5;
    border-radius: 7px;
}

.userinfo-txt {
    display: block;
    color: inherit;
    font-size: 16px !important;
    padding: 5px 20px;
    font-weight: 600 !important;
}

.menudate-list-txt-style1 {
    font-size: 22px;
    margin-left: 17%;
    margin-top: 3%;
    font-weight: 600;
}
// Corporate::after

.corp-hight-100 {
    height: 594px;
}

//Home
.slick-prev {
    display: none !important;
}

.slick-next {
    display: none !important;
}

.mainhome-slider-container {
    width: 80%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.mainhome-slider-container:before {
    content: "";
    display: block;
    position: absolute;
    top: -200px; /* adjust the top position to create the desired shape */
    left: -50%;
    // width: 200%;
    height: 400px; /* adjust the height to create the desired shape */
    border-radius: 50%;
    background-color: #eee;
}

.mainhome-slider-item {
    position: relative;
    z-index: 1;
    // padding: 10%;
}

.mainhome-slider-item img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}
.mainhome-txt {
    color: #fff;
    text-align: center;
    padding-top: 10%;
}

.mainhome-h1-txt {
    font-size: 50px;
    color: aliceblue;
}
.home-sub-btn {
    border: 1px solid black !important;
    color: white !important;
    background-color: rgb(0 0 0) !important;
    border-radius: 25px !important;
}
.br-radius-25 {
    border-radius: 25px !important;
}
.postion {
    position: absolute;
}
// .slick-slide.slick-active.slick-current {
//     margin-top: 2%;
// }
// .slick-slide.slick-active {
//     margin-top: 2%;
// }
.img-box img {
    border-radius: 0 45px 0 45px !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.serv-style-img:hover {
    // border: 3px solid #c60800;
    // border-radius: 9px !important;
    box-shadow: -7px 19px 2px 8px #dc0000;
}
.box-img {
    width: 100%;
    min-height: 530px;
    height: 100%;
}
.box-img1 {
    width: 100%;
    // min-height: 530px;
    height: 100%;
}

.heading_container h2 {
    font-size: 2.5rem;
    position: relative;
    // font-weight: bold;
    margin-bottom: 8%;
    font-weight: 800;
    padding-bottom: 7px;
    text-align: center;
}

.heading_container h2::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 75px;
    height: 5px;
    background-color: #dc0000;
}
.app_section .detail-box .app_btn_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 35px 0;
}
.nav-arrow {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    background-color: #000000;
    border-radius: 100%;
    font-size: 12px;
    color: #ffffff;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-top: 4%;
    margin-left: 50%;
}
.mainhome-footer_container {
    background-size: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top;
    padding-top: 245px;
    margin-top: -175px;
}
.info_section .contact_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 350px;
    margin: auto;
    margin-top: 6%;
    margin-bottom: 6%;
}
.info_section .info_links {
    margin: 25px 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: 100%;
}
.info_section .info_links li {
    display: initial;
    padding: 35px;
}
.info_section .social_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 3% 0%;
}
.footer_section {
    position: relative;
    text-align: center;
}
.copyright-txt {
    color: white !important;
    font-weight: 600;
    font-size: 18px;
    padding: 0px 10px;
}
.food-safty-status-txt {
    margin-top: 4% !important;
}

.stper-end-txt {
    margin-top: "20px";
    color: #000;
}
.stper-end-txt-done {
    margin-top: "20px";
    color: #029e0a;
}
//
.home-slide-cat-img {
    position: relative;
    // width: 50%;
}

.slider-image-style {
    display: block;
    // width: 100%;
    height: auto;
}

.hover-txt-style {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 73%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #000000d9;
    z-index: 1;
    border-radius: 50%;
    margin-left: 58px;
    padding: 0% 0% 74% 0%;
    margin-top: -3px;
}

/* Styles for screens up to 600px */
@media (max-width: 600px) {
    .hover-txt-style {
        background-color: #000000d9;
        border-radius: 50%;
        bottom: 0;
        height: 100%;
        left: 0;
        margin-left: 27px;
        margin-top: -2px;
        opacity: 0;
        padding: 0 0 86% 0%;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.5s ease;
        width: 85%;
        z-index: 1;
    }
}

/* Styles for screens between 600px and 1200px */
@media (min-width: 600px) and (max-width: 1200px) {
    .hover-txt-style {
        background-color: #000000d9;
        border-radius: 50%;
        bottom: 0;
        height: 100%;
        left: 0;
        margin-left: 27px;
        margin-top: -2px;
        opacity: 0;
        padding: 0 0 86% 0%;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.5s ease;
        width: 85%;
        z-index: 1;
    }
}
.MuiListItemText-root.css-tlelie-MuiListItemText-root {
    width: 100% !important;
}

//   .home-slide-cat-img:hover .slider-image-style {
//     opacity: 0.3;
//   }

.home-slide-cat-img:hover .hover-txt-style {
    opacity: 1;
}

.hover-txt-center {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

//
//   .container {
//     position: relative;
//     width: 50%;
//   }

//   .image {
//     display: block;
//     width: 100%;
//     height: auto;
//   }
.dcimagestyle {
    width: 100% !important;
}
.stywdth50 {
    width: 35% !important;
}
.MuiListItemText-root.css-1tsvksn {
    width: 50%;
}
.span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
    font-weight: 700;
}
.fd-sft {
    text-align: center !important;
    width: 100%;
    max-width: 100%;
}
.mt-top-4 {
    margin-top: 1% !important;
    margin-bottom: 1%;
}
.d-flex1 {
    display: flex !important;
}
.span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-yb0lig {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.slid123 {
    position: relative;
    width: 77%;
    margin-left: 1px;
}

.slid123-pic {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin-left: 15%;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #000000d7;
    border-radius: 50%;
    border: 5px solid rgb(255, 238, 0);
    margin-left: 15%;
}

.slid123:hover .overlay {
    opacity: 1;
}

.slid123-text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.password-icon-style {
    position: absolute !important;
    left: 65%;
    padding-top: 1.5% !important;
}
.rquest-mail-tit {
    color: #c60800;
    text-align: center;
    background-color: #fff0f0;
    padding: 1% 0%;
}
.pdtp-6 {
    padding-top: 6% !important;
}
//

.sp-fileuploade-input-style {
    background-color: white;
    padding: 5px;
    width: 82%;
    border: 2px solid #9f9f9f94;
    border-radius: 9px;
}

//

.number-font {
    font-family: system-ui;
    font-weight: 600;
}
.proview-btn {
    background-color: aliceblue;
    border: none;
    padding: 2% 14%;
    color: #0060b5;
    font-size: 18px;
    font-weight: 600;
}
.profile-card {
    background-color: white;
    margin: 0% 0%;
    line-height: 27px;
    text-align: center;
    padding: 0% 0%;
    box-shadow: rgba(0, 0, 0, 0.27) 3px 5px 8px;
    padding-bottom: 3%;
}
.profile-list {
    border-bottom: 1px solid gray;
    margin-right: 12%;
}
.profile-card1 {
    background-color: white;
    margin: 0% 0%;
    line-height: 27px;
    padding: 0% 0%;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.6) 2px 4px 8px;
    padding-bottom: 5%;
}
.pro-listtxt-style {
    display: flex;
}
.pofile-listicon {
    display: flex;
    justify-content: end;
}
.pro-list-txt {
    font-weight: 700;
    font-size: 22px;
    margin-left: 3%;
    color: black;
}
.faq-container {
    margin: 6% 0%;
}

.faq-tit-txt {
    text-align: center;
    padding: 6% 0%;
    font-size: 35px;
}
.mainpage-login-btn {
    background-color: #ffffff00;
    border: none;
    font-size: 18px;
    font-weight: 600;
    color: black;
    margin-right: 3%;
}
.cr-style-list {
    display: contents;
}
.mainpage-signup-btn {
    background-color: #000000;
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: white;
    margin-right: 3%;
    padding: 4px 18px;
}

.mobile-bg-color {
    text-align: center;
    background-color: rgb(0 0 0 / 74%);
    height: 100%;
    width: 100%;
    color: #fff;
}

.view-center-footer-style {
    display: flex;
    justify-content: center;
    align-items: center;
}
//new home style::after
.mainhome-slider-container12 {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background-color: #000;
}
.mainslid123-pic {
    display: block;
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
    margin-left: 18%;
}
.mainhome-service-style {
    text-align: center;
    color: aliceblue;
    margin: 4% 0% 2% 0%;
    font-size: 40px;
}
.mainhome-service-slide-title {
    color: #c60800;
    font-weight: 800;
    font-size: 24px;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    text-align: center;
    margin-left: 30%;
    margin-top: 2%;
}
.mainhome-slid123 {
    position: relative;
    width: 400px;
    margin-left: 1px;
    height: 300px;
}
//

.flip-card {
    width: 400px; /* Adjust this width to fit your image size */
    height: 360px; /* Adjust this height to fit your image size */
    perspective: 1000px;
}

.flip-card-inner {
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #00000013;
}

.flip-card-back {
    background-color: #c60800;
    color: #fff;
    transform: rotateY(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -17%;
    border: 1px solid yellow;
}

.flip-card-back p {
    padding: 10px;
    font-size: 16px;
}
.ds-1 {
    position: absolute;
    left: 1%;
    bottom: 38%;
}

.ds-1 img {
    width: inherit;
    animation: moveLeftAndRight 5s infinite linear;
}
@keyframes moveLeftAndRight {
    0%,
    100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(100%);
    }
}

.ds-2 {
    position: absolute;
    right: 1%;
    bottom: 38%;
}
.ds-2 img {
    width: inherit;
    animation: bounce 5s infinite ease-in-out;
}
@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}
.ds-3 {
    position: absolute;
    right: 1%;
    bottom: 8%;
}

.ds-3 img {
    width: inherit;
    animation: moveRightAndLeft 5s infinite linear;
}
@keyframes moveRightAndLeft {
    0%,
    100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-100%);
    }
}

.ds-4 {
    position: absolute;
    right: 1%;
    bottom: 74%;
}

.ds-4 img {
    width: inherit;
    animation: bounce 5s infinite ease-in-out;
}
@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

// .ds-5 {
//     position: absolute;
//     right: 1%;
//     bottom: 38%;
// }
// .ds-5 img {
//     width: inherit;
//     animation: bounce 5s infinite ease-in-out;
// }
// @keyframes bounce {
//     0%,
//     100% {
//         transform: translateY(0);
//     }
//     50% {
//         transform: translateY(-20px);
//     }
// }
// .ds-6 {
//     position: absolute;
//     right: 1%;
//     bottom: 8%;
// }

// .ds-6 img {
//     width: inherit;
//     animation: moveRightAndLeft 5s infinite linear;
// }
// @keyframes moveRightAndLeft {
//     0%,
//     100% {
//         transform: translateX(0);
//     }
//     50% {
//         transform: translateX(-100%);
//     }
// }

//
.main-about-img-style {
    height: 430px;
    margin-top: 7%;
    margin-bottom: 15%;
    width: 478px;
    // margin-left: 7%;
}
.main-about-img-style-moniter {
    height: 465px;
    margin-top: 7%;
    margin-bottom: 15%;
    width: 100%;
    // margin-left: 7%;
}
.main-about-title-icon {
    width: 11%;
    height: 20%;
    position: absolute;
    left: 0%;
    top: 5%;
}

//
.mainhome-ordersteps-style {
    text-align: center;
    color: aliceblue;
    margin: 0% 0% 2% 0%;
    font-size: 40px;
    padding-top: 6%;
}
.sp-fileuploade-input-style {
    background-color: white;
    padding: 5px;
    width: 82%;
    border: 2px solid #9f9f9f94;
    border-radius: 9px;
}
.file-input-container {
    position: relative;
}

.file-input-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
}
.iso-txt {
    display: flex;
    align-items: center;
    justify-content: center;
    color: aliceblue;
}
.aline-center-footer-txt {
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-icon-style {
    color: #c60800;
    font-size: 28px !important;
}
.footer-icon-txt-style {
    font-size: 30px;
    font-weight: bolder;
    color: white;
    // padding-left: 2%;
}
.footer-contact-p {
    color: #ffffff;
    text-align: center;
}
.footer-contact-p1 {
    color: #ffffff !important;
    // text-align: center;
}
.ds-5 {
    position: absolute;
    left: 0%;
}
.footer-line {
    color: aliceblue;
    /* height: 3px; */
    border: 1px solid #fff;
    margin: 2% 6%;
}

.footer-color-txt {
    color: #fff;
}
.footer-color-txt:hover {
    color: #ffc107;
}
//
.sliding-text {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    position: absolute;
    bottom: 33%;
    left: -15%;
    color: #ffc107;
    font-size: 20px;
    font-weight: 700;
}

.slide-in {
    opacity: 1;
    transform: translateX(0);
}

.sliding-text1 {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    position: absolute;
    bottom: 70%;
    left: -20%;
    color: #ffc107;
    font-size: 20px;
    font-weight: 700;
}
.slide-in1 {
    opacity: 1;
    transform: translateX(0);
}

.sliding-text2 {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    position: absolute;
    bottom: 70%;
    left: 61%;
    color: #ffc107;
    font-size: 20px;
    font-weight: 700;
}

.slide-in2 {
    opacity: 1;
    transform: translateX(0);
}

.sliding-text3 {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    position: absolute;
    bottom: 34%;
    left: 61%;
    color: #ffc107;
    font-size: 20px;
    font-weight: 700;
}

.slide-in3 {
    opacity: 1;
    transform: translateX(0);
}

.sliding-line1 {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    position: absolute;
    bottom: 63%;
    left: 16%;
}
.sliding-line2 {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    position: absolute;
    bottom: 26%;
    left: 16%;
}
.sliding-line3 {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    position: absolute;
    bottom: 63%;
    left: 61%;
}
.sliding-line4 {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    position: absolute;
    bottom: 27%;
    left: 61%;
}
.slide-in5 {
    opacity: 1;
    transform: translateY(0);
}

.service-list-tit {
    font-size: 19px;
    font-weight: bolder;
    color: black;
    padding-left: 2%;
    padding-top: 8px;
}

.service-list-card-style {
    box-shadow: 7px 5px 0px 2px rgba(255, 247, 0, 0.336);
    border: 2px solid #fffcb2;
    border-radius: 7px;
    padding: 3% 1%;
    margin-bottom: 4%;
}
//   .sliding-foote-1 {
//     opacity: 0;
//     transform: translateX(-100%);
//     transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
//   }

//   .slide-in-text {
//     opacity: 1;
//     transform: translateX(0);
//   }

//   .sliding-foote-2 {
//     opacity: 0;
//     transform: translateX(100%);
//     transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
//   }

//   .slide-in-text1 {
//     opacity: 1;
//     transform: translateX(0);
//   }

//

.cart-list-close-btn {
    background-color: rgb(207 207 207);
    color: rgb(255, 255, 255);
    border: 1px solid #b3b3b3;
    padding: 2% 8%;
    font-weight: 800;
}
.checkout-billlist-style {
    line-height: 35px;
    border-bottom: 1px solid gray;
}
.cancel-fee-table table {
    border-collapse: collapse;
    width: 100%;
}
.cancel-fee-table table,
.cancel-fee-table td,
.cancel-fee-table th {
    border: 1px solid #05baff ;
}
.cancel-fee-table th {
    background-color: #50a6ff;
    color: white;
    font-size: 20px;
    font-weight: 800;
}
.cancel-fee-table th,
.cancel-fee-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #05baff  ;
}

.cancel-fee-table tr:hover {
    background-color: #e7e7e7;
}

//
.para-fontsize {
    font-size: 20px !important;
    line-height: 30px;
}
.spiner-span-title {
    font-size: 25px;
    display: block;
    font-weight: 700;
    padding-bottom: 10px;
}
//
/* Carousel.css */
.carousel-container {
    display: flex;
    overflow: hidden;
}

.slide {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
}

.slide img {
    width: 100%;
    height: auto;
}

.gradient-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to right,
        //  #ff0000 0%,
        //  #ff9900 25%,
        #000000c7 40%,
        // #33cc33 75%,
        #0000ff00 100%
    );
    // background: linear-gradient(to right, #000000, #ffffff00);
    // opacity: 0;
    // animation: showMask 1s ease-in-out infinite;
}

//   @keyframes showMask {
//     0%, 100% {
//       opacity: 1;
//     }
//     50% {
//       opacity: 0;
//     }
//   }

.slide-text {
    position: absolute;
    bottom: 50%;
    left: 10%;
    transform: translateY(50%);
    color: #ffffff;
    font-size: 3.7rem;
    font-weight: bolder;
    width: 55%;
}
.slide-text-p {
    position: absolute;
    bottom: 30%;
    left: 10%;
    // transform: translateY(50%);
    // color: #c60800;
    // font-size: 1.2rem;
    // font-weight: bold;
}

/* Add blinking spots animation */
//   .gradient-mask::after {
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     width: 6px;
//     height: 6px;
//     background-color: #000;
//     border-radius: 50%;
//     animation: blink 1s infinite;
//   }

//   @keyframes blink {
//     0%, 100% {
//       transform: scale(1);
//     }
//     50% {
//       transform: scale(1.2);
//     }
//   }
ul,
li {
    list-style: none !important;
}
.home-nav-panel {
    height: 54px;
    background: #00000061;
    box-shadow: 0 1px rgba(0, 0, 0, 0.04), inset 0 -1px rgba(0, 0, 0, 0.04);
    color: #ffffff;
    position: fixed;
    width: 100%;
    z-index: 1;
}
.re-btn {
    position: absolute;
    bottom: 30%;
    left: 10%;
    background-color: #c60800;
    padding: 1% 2%;
    color: aliceblue;
    border: none;
    border-radius: 5px;
    font-weight: 700;
}
.dawer-txt1:hover {
    color: #f9e830 !important;
}
.dawer-txt1 {
    width: 100% !important;
}
.pp-services {
    text-align: center;
    color: #fff;
    padding: 0% 25%;
}
.ab-service1 {
    font-size: 60px;
    margin-left: 15%;
}
.footer-b {
    width: 72%;
}
.footer-hb {
    padding: 0% 0%;
}

.header-main-link-tex {
    width: 145px !important;
    display: flex;
}

.float1234 {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 38px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

// mainhome2 start
.mi-main-style {
    background-color: #000000;
    color: #ffffff;
    font-family: Manrope Variablefont Wght, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
.m3-font-style {
    font-family: Manrope Variablefont Wght, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
.just-up-text {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}
.itemcenter {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.yellow-color {
    color: #f9e830 !important;
}

.card-bg-style {
    border-radius: 1rem !important;
    background-color: #2f3437;
    color: #ffffff;
    border: none !important;
}
.sv-card-images {
    height: 280px;
    margin-left: 1px;
    position: relative;
    overflow: hidden;
}
.app-bg-style {
    background-image: linear-gradient(154deg, #ff2b2b, #7a0500);
    border-radius: 1rem !important;
    color: #ffffff;
    border: none !important;
    padding: 3% 0% 0% 0%;
    margin-top: 5%;
    margin-bottom: 5%;
    // border: 2px dotted #c60800;
}
.app-bg-style123 {
    background-image: linear-gradient(154deg, #1c1c1c, #000000);
    border-radius: 1rem !important;
    color: #ffffff;
    border: 3px solid #c60800 !important;
    padding: 3% 0% 3% 0%;
    margin-top: 5%;
    margin-bottom: 5%;
}
.app-bg-style123:hover {
    background-image: linear-gradient(154deg, #1c1c1c, #000000);
    border-radius: 1rem !important;
    color: #ffffff;
    border: 3px solid #f9e830 !important;
    padding: 3% 0% 3% 0%;
    margin-top: 5%;
    margin-bottom: 5%;
}
.cnt-btn-style-main {
    color: #01080b;
    text-align: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}
.cnt-btn-style-main:hover {
    color: #ffffff;
    text-align: center;
    background-color: #c60800;
    border: none;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}
.intro-homebanner-style {
    color: rgb(255, 255, 255);
    padding-top: 20%;
    width: 100%;
    padding-bottom: 20%;
    text-align: end;
    padding-left: 48%;
    padding-right: 5%;
}
.footer-social-itemcenter-style {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-left: 1%;
}
.footer-social-icon-style {
    background-color: #1d2326;
    padding: 5px;
    color: aliceblue;
    border-radius: 7px;
    font-size: 35px !important;
}
.footer-social-icon-style:hover {
    background-color: #f9e830;
    padding: 5px;
    color: rgb(0, 0, 0);
    border-radius: 7px;
    font-size: 35px !important;
}

.main-head-nave-panel-style {
    height: 54px;
    background: #00000061;
    box-shadow: 0 1px rgba(0, 0, 0, 0.04), inset 0 -1px rgba(0, 0, 0, 0.04);
    color: #ffffff;
    // position: fixed;
    width: 100%;
    z-index: 1;
}

// mainhome2 end

// mainhome start
.m3navbar-container {
    width: 100%;
    height: 100%;
    max-width: 92rem;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.m3navbar-menu {
    align-items: center;
    display: flex;
    justify-content: center;
    // margin-bottom: 0%;

    // position: static;
}
.m3navbar-menu li {
    padding: 2% 2%;
    margin-top: 3%;
    // font-size: 18px;
}
.m3navbar-menu li a {
    color: #ffffff;
}
.m3navbar-menu li a:hover {
    color: #c60800;
    // font-size: 18px;
    font-weight: 600;
}
.m3-rg-btn-nav-style {
    background-color: white;
    border-radius: 27px;
    padding: 1px 24px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    margin-left: 7%;
    width: 100%;
    height: 100%;
}
.m3-rg-btn-nav-style:hover {
    background-color: #c60800 !important;
    color: #ffffff;
    border-radius: 27px;
    padding: 1px 24px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    margin-left: 7%;
    width: 100%;
    height: 100%;
}
.m3-mainhome-navbar-style {
    background: #000000b5;
    box-shadow: 0 1px rgba(0, 0, 0, 0.04), inset 0 -1px rgba(0, 0, 0, 0.04);
    color: #fff;
    height: 65px;
    position: fixed;
    width: 100%;
    padding: 0% 8%;
    z-index: 1;
}
.padding-section-large {
    padding-top: 10rem;
    padding-bottom: 7rem;
}
.m3-home-header-component {
    grid-column-gap: 0rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    display: grid;
}
.m3-home-header-content {
    max-width: 44rem;
    margin-left: 9vw;
    //    margin-right: 5rem;
    justify-self: end;
}
.m3-anime-mainhome {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}
.m3-spacer-small {
    width: 100%;
    padding-top: 1.5rem;
}
.m3-spacer-large {
    padding-top: 3rem;
}
.m3-button-group {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}
.m3-mainscreen-btn-style {
    color: #01080b;
    text-align: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}
.m3-sidebare-btn-style {
    color: #ffffff;
    text-align: center;
    background-color: #c60800;
    border: 1px solid #fff;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}
.m3-mainscreen-btn-style1 {
    color: #ffffff;
    text-align: center;
    background-color: #ffffff00;
    border: 1px solid #fff;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}

.m3-mainscreen-btn-style:hover {
    color: #ffffff;
    text-align: center;
    background-color: #c60800;
    border: none;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}
.m3-mainscreen-btn-style1:hover {
    color: #ffffff;
    text-align: center;
    background-color: #c60800;
    border: none;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}
.m3-mainscreen-img {
    position: relative;
}
.home-header-image {
    width: 100%;
    object-fit: cover;
}
.m3-illustration {
    z-index: 1;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: -20%;
    right: auto;
}
// .m3-fade-in-left:focus-within {
//     opacity: 0;
//     transform: translateX(-50px);
//     animation: fadeInLeftAnimation3 1s ease forwards;
//   }

//   @keyframes fadeInLeftAnimation3 {
//     from {
//       opacity: 0;
//       transform: translateX(-50px);
//     }
//     to {
//       opacity: 1;
//       transform: translateX(0);
//     }
//   }

.m3fade-in-left {
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 1s, transform 1s;
}

.m3fade-in-left.visible {
    opacity: 1;
    transform: translateX(0);
}
.m3fade-in-right {
    opacity: 0;
    transform: translateY(40px);
    transition: opacity 1s, transform 1s;
}

.m3fade-in-right.visible {
    opacity: 1;
    transform: translateY(0);
}
.h1-m3slider-text {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 45px;
    // font-weight: 700;
    // line-height: 1.1;
}
.m3slider-container {
    width: 100%; /* Set a suitable width */
    height: 100%; /* Set a suitable height */
    margin: 0 auto;
}
.m3slider-container img {
    width: 60%;
}
.padding-page-m3 {
    padding: 3% 7%;
}
.m3-card-wdith {
    margin-top: 7%;
}
.m3-h2-text-style {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0;
    width: 90%;
}
.para-fontsize-m3 {
    font-size: 18px !important;
    line-height: 30px;
}
.padding-testimonial-m3 {
    padding: 9% 9%;
}
.padding-ab-m3 {
    padding: 0% 9%;
}
.m3-clinte-img-style {
    padding: 10% 0%;
    display: flex;
    justify-content: center;
}
.client-m3-height {
    height: 500px;
}
.mg-10-tp {
    margin-top: 0%;
}

.lg-style-logo-img-w {
    width: 28%;
}
.lg-style-txt-mt {
    color: red;
    margin-top: 15%;
}
.pd-clint-name {
    padding-top: 20px !important;
    padding-left: 10px;
}
.card-blog-style {
    border-radius: 7px;
    background-color: #000000;
    padding: 4%;
}
.card-blog-style:hover {
    border-radius: 7px;
    background-color: #000000;
    padding: 4%;
    border: 2px solid #f9e830;
}
.card-blog-top-style {
    width: 100%;
    height: 270px;
    border-radius: 1rem;
    overflow: hidden;
}
.card-blog-top-style img {
    transition: transform 0.3s ease;
}
.card-blog-top-style:hover img {
    transform: scale(1.1);
}
.card-blog-buttom-style {
    width: 100%;
    height: 200px;
    color: #ffffff;
}
.blog-view-btn {
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.card-blog-top-style-all-img {
    transition: transform 0.3s ease;
}
.card-blog-top-style-all-img:hover {
    transform: scale(1.1);
}

//
.m3scroll-animation {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-margin-top: 10vh; /* Adjust this value as needed */
}

.m3content {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.m3scroll-animation:hover .m3content,
.m3scroll-animation.in-view .m3content {
    opacity: 1;
    transform: translateX(0);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.m3scroll-animation {
    animation: fadeIn 1s ease-in-out;
    animation-fill-mode: both;
}
//
.m3scroll-animation-top {
    // height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-margin-top: 5vh; /* Adjust this value as needed */
}
.m3content-top {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.m3scroll-animation-top:hover .m3content-top,
.m3scroll-animation-top.in-view .m3content-top {
    opacity: 1;
    transform: translateY(0);
}

@keyframes fadeInRight {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.m3scroll-animation-top {
    animation: fadeInRight 1s ease-in-out;
    animation-fill-mode: both;
}
.m3-futures-style {
    margin-top: 15%;
    width: 80%;
}

.faq-section {
    overflow-y: hidden !important;
    height: 100%;
    width: 80% !important;
    border: 1px solid #ffffff57;
    border-radius: 7px;
    margin: 2% 0%;
    margin-left: 10%;
}
.faq-section:hover {
    overflow-y: hidden !important;
    height: 100%;
    width: 80% !important;
    border: 2px solid #c60800;
    border-radius: 7px;
    margin: 2% 0%;
    margin-left: 10%;
}
.faq-heading {
    height: 65px;
    font-weight: 700;
}
.itemcenter123 {
    // right: -7%;
    width: 100%;
    position: relative;
}
.faqs-titel-text-style {
    text-align: center;
    padding: 6%;
}
.faqs-vendor-style {
    background-image: linear-gradient(154deg, #ff2b2b, #7a0500);
    border-radius: 1rem !important;
    color: #ffffff;
    border: none !important;
    padding: 3% 0% 0% 0%;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 55%;
    /* display: flex; */
    position: relative;
    left: 24%;
}
.cnt-btn-style-main123-vendor {
    color: #01080b;
    text-align: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}
.cnt-btn-style-main123-vendor:hover {
    color: #ffffff;
    text-align: center;
    background-color: #f9e830;
    border: 2px solid #fff;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}
.show-faq-2 {
    height: auto !important;
}
.app-playstore-width {
    width: 30%;
}
.m3-counter-h2 {
    color: rgb(198, 8, 0);
    font-family: system-ui;
    font-size: 45px;
}
.m3-main-miles-img-style {
    height: 430px;
    margin-top: 7%;
    margin-bottom: 15%;
    width: 100%;
}
.m3-main-img {
    width: 65% !important;
    height: 100%;
    border-radius: 10px;
    border: 2px solid #c60800;
}
.m3-main-img:hover {
    width: 65%;
    height: 100%;
    border-radius: 10px;
    border: 2px solid #f9e830;
}
.card-mobileapp-style {
    border-radius: 7px;
    background-color: #c60800;
    padding: 8%;
    height: 100%;
    width: 100%;
}
.mbap-btn-style-main {
    color: #ffffff;
    text-align: center;
    background-color: #000;
    border: none;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}
.mbap-btn-style-main:hover {
    color: #000;
    text-align: center;
    background-color: #fff;
    border: none;
    border-radius: 6.25rem;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    text-decoration: none;
}
.m3-card-bottom {
    padding-bottom: 7%;
}
.m3-main-text-pad-style {
    padding: 1% 9%;
}
.m3-blog-tit-style {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5;
}
.m3-font-600 {
    font-weight: 600;
}
.m3-font-700 {
    font-weight: 700;
}
.m3-font-style-n {
    font-family: Manrope Variablefont Wght, sans-serif;
}
// .m3-all-fade-in {
//     opacity: 0;
//     animation: fadeInAnimation3 3s ease-in forwards;
//   }
  
//   @keyframes fadeInAnimation3 {
//     from {
//       opacity: 0;
//     }
//     to {
//       opacity: 1;
//     }
//   }

.fade-in-section {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 1s ease-out;
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: translateY(0);
  }

.m3-all-fade-in {
    opacity: 0; /* Set initial opacity to 0 */
    transform: translateY(20px); /* Optional: Add some vertical offset */
    animation: fadeInUp3 1s ease-in-out forwards; /* Apply animation */
  }
  
  @keyframes fadeInUp3 {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes zoomInm3 {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  
  .zoom-in-section {
    animation: zoomInm3 1.5s; /* Adjust the duration as needed */
  }
  .fixed-header-mobile{
    position: fixed;
    z-index: 1;
  }

  

  

  // ODC
  .rs-menu-titel-style{
    background-color: rgb(0 0 0 / 53%);
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .rs-menu-event-list{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 48px;
    border-radius: 35px;
    margin: 0% 0%;
    font-weight: 600;
    font-size: 18px;
    border: none;
    background: none;
  }
  .rs-menu-event-list-active{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 30px;
    border: 1px solid rgb(255, 255, 255);
    background-color: #c60800;
    color: #fff;
    border-radius: 35px;
    margin: 0% 1%;
    font-weight: 600;
    font-size: 18px;
  }
  .rs-menu-event-list-active:active{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 30px;
    border: 1px solid rgb(255, 255, 255);
    background-color: #c60800;
    color: #fff;
    border-radius: 35px;
    margin: 0% 1%;
    font-weight: 600;
    font-size: 18px;
  }
  .menu-cambo-price{
    background-color: #c60800;
    padding: 4%;
    color: white;
    display: flex;
    align-items: center;
  }
  .menu-cambo-h4-style{
    margin-top: 7%;
    margin-left: 4%;
  }
  .rs-menu-list-ul-style{
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    line-height: 45px;
  }
  .rs-menu-container{
    padding: 3% 6%;
  }
 
  .card-styleborder{
    border-radius: 15px;
    padding: 15px;
    box-shadow: 4px 0px 11px 2px rgb(8 187 255 / 12%);
    width: 100%;
    margin-bottom: 10%;
  }
  .card-styleborder:hover{
    border-radius: 15px;
    padding: 15px;
    box-shadow: -8px -3px 0px 0px rgba(255, 8, 8, 0.17);
    width: 100%;
    margin-bottom: 10%;
  }
  .min-p-style{
    text-align: center;
    padding-top: 2%;
    color: #c60800;
  }
  .odc-card-add-btn{
    background-color: black;
    border: none;
    padding: 5px 25px;
    font-size: 20px;
    color: aliceblue;
    border-radius: 24px;
  }
  .odc-card-add-btn1{
    background: none;
    border: none;
    padding: 5px 25px;
    font-size: 20px;
    font-weight: 600;
    color: #c60800;
    border-radius: 24px;
    box-shadow: 0px 0px 6px 2px rgb(2 2 2 / 12%);
  }
  .odc-card-add-btn1:hover{
    background-color: #000;
    border: none;
    padding: 5px 25px;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 0px 6px 2px rgb(2 2 2 / 12%);
  }
  .odc-card-add-btn:hover{
    background-color: #c60800;
    border: none;
    padding: 5px 25px;
    font-size: 20px;
    color: aliceblue;
    border-radius: 24px;
  }
  .odc-card-input-style{
    font-size: 20px;
    font-family: revert;
    /* font-weight: 600; */
    border-radius: 36px;
    border: none;
    text-align: center;
    width: 35%;
    padding: 5px 0px;
    box-shadow: 0px 0px 6px 2px rgb(2 2 2 / 12%);
  }
  .odc-chechout-input-style {
    font-size: 20px;
    font-family: revert;
    /* font-weight: 600; */
    border-radius: 36px;
    border: none;
    text-align: center;
    width: 90%;
    padding: 5px 0px;
    box-shadow: 0px 0px 6px 2px rgba(2, 2, 2, 0.12);
}
  .odc-card-pluse-btn{
    background: none;
    font-size: 20px;
    border: none;
    /* border-radius: 55%; */
    padding: 0% 2%;
    position: absolute;
    margin-left: -8%;
    margin-top: 1%;
    font-weight: 700;
  }
  .odc-card-mines-btn{
    background: none;
    font-size: 20px;
    border: none;
    /* border-radius: 55%; */
    padding: 0% 2%;
    position: absolute;
    margin-left: 2%;
    margin-top: 1%;
    font-weight: 700;
  }
  .odc-view-card-btn-style{
    border: none;
    background-color: white;
    padding: 7px 15px;
    border-radius: 17px;
    font-weight: 600;
    color: #000000;
  }
  .odc-view-card-btn-style:hover{
    border: none;
    background-color: #c60800;
    padding: 7px 15px;
    border-radius: 17px;
    font-weight: 600;
    color: #ffffff;
  }
  .odc-cartic-list-main{
    padding: 1% 0%;
    font-size: 16px;
    font-weight: 600;
  }
  .odc-cartic-list-sub{
    padding: 0% 0%;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
//   .odc-addcart-menu-list-style thead{
// background-color: #858585;
// color: #ffffff;
// border-collapse: collapse;
// width: 100%;
//   }



  .odc-addcart-menu-list-style table {
    border-collapse: collapse;
    width: 100%;
}
.odc-addcart-menu-list-style table {
    border: 1px solid #ebebeb ;
}
.odc-addcart-menu-list-style th {
    background-color: #f7f7f7;
    color: rgb(77, 77, 77);
    font-size: 20px;
    // font-weight: 800;
}
.odc-addcart-menu-list-style th,
.odc-addcart-menu-list-style td {
    padding: 8px;
    text-align: left;
    // border-bottom: 1px solid #ebebeb  ;
}

// .odc-addcart-menu-list-style tr:hover {
//     background-color: #e7e7e7;
// }
.odc-card-mines-btn-cart {
    background: none;
    font-size: 20px;
    border: none;
    /* border-radius: 55%; */
    padding: 0% 2%;
    position: absolute;
    margin-left: 0%;
    margin-top: 7px;
    font-weight: 700;
}
.odc-checkout-mines-btn-cart {
    background: none;
    font-size: 20px;
    border: none;
    /* border-radius: 55%; */
    padding: 0% 2%;
    position: absolute;
    margin-left: 8%;
    margin-top: 7px;
    font-weight: 700;
}
.odc-menu-card-img-style{
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 257px;
    padding: 0% 3%;
    overflow: hidden;
    position: relative;
}
.odc-menu-card-img-style img {
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease,-webkit-transform .3s ease;
}
.odc-card-menuname-txt-style{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}
.odc-card-pluse-btn-cart {
    background: none;
    font-size: 20px;
    border: none;
    /* border-radius: 55%; */
    padding: 0% 2%;
    position: absolute;
    margin-left: -17%;
    margin-top: 7px;
    font-weight: 700;
}
.odc-cart-totel{
    background-color: #f7f7f7;

}
.odc-cart-totel1 {
    background-color: #c60800;
    color: white;
    border-radius: 15px;
}
.odc-addservice-sub-btn-style{
    padding: 0% 6%;
    background: #f0f8ff00;
    border: 2px solid #f00;
    border-radius: 56px;
    font-size: 20px;
}
.odc-addservice-sub-btn-style:hover{
    padding: 0% 6%;
    background: #c60800;
    border: 2px solid rgba(255, 0, 0, 0);
    border-radius: 56px;
    color: #fff;
    font-size: 20px;
}
.payment-container{
    margin: auto !important;
    width: 360px;

}
.payment-card-style{
    width: 90% !important;
    padding: 0px 14px;
    // border: 1px #dddbdb solid;
    border-radius: 6px;
    display: grid;
    margin: 10% 0%;
    box-shadow: 0px 1px 9px 2px rgb(0 0 0 / 7%);
}
.payment-methods-style{
    text-align: left;
    padding: 16px !important;
    margin: 16px 0px !important;
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.02);
    line-height: 1.4;
    background: white;
}
.payment-cancel-btn-style{
    width: 100%;
    height: 40px;
    background-color: #fff;
    border-style: none;
    border: 1px solid #EDEDED !important;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.02);
    border-radius: 18px;
    color: #1F6D85;
    float: left;
    cursor: pointer;
}
.payment-totel-style{
    background-color: #EAFDFF;
    width: 100%;
    padding: 10px;
    border-radius: 16px;
    margin: 0 auto;
    box-sizing: border-box;
}
.payment-icon-color{
    color: #c60800;
    margin-right: 2%;
}
.payment-card-input-style{
    border: none;
    width: 100%;
    border-bottom: 2px solid #EFEFEF;
    outline: none !important;
    font-size: 14px;
}
.payment-cards-icon-style{
 
    color:  #bdbdbd;
    margin-right: 2%;
   
}
.payment-back-btn-style{
    width: 100px;
    height: 40px;
    background-color: #fff;
    border-style: none;
    border: 1px solid #EDEDED !important;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.02);
    border-radius: 18px;
    color: #1F6D85;
    float: left;
    cursor: pointer;
}
.payment-paynow-btn-style{
    width: 100px;
    height: 40px;
    border-radius: 18px;
    border-style: none;
    border: 1px solid #EDEDED !important;
    color: #fff;
    cursor: pointer;
    float: right;
    background: linear-gradient(270deg, #efa0a0 -100%, #d24545 41.35%, #801b1b 116%);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.02);
}
.payment-dec-text-style{
    background-color: #EAFDFF;
    /* height: 70px; */
    padding: 10px 15px;
    padding-left: 20px !important;
    position: relative;
    color: #3495B1;
    border-radius: 16px;
    margin: 0 auto;
}
.odc-cartlist-td-20{
    width: 15%;
}
.odc-final-steps-icon{
    font-size: 42px !important;
}
.odc-final-steps-lg-btn{
   
    font-weight: 600 !important;
    color: white;
    padding: 2% 9%;
    margin: 1% 5%;
    background-color: #007bff;
    border-radius: 3px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.39);
    border: 1px solid #00000021;
    margin-top: 4%;
}
.odc-final-steps-input{
    border: 2px solid #d1d1d1;
    padding: 2% 3%;
    width: 55%;
}
.odc-header-img-width{
    width: 644px;
    height: 100%;
}
.odc-header-meimg-width{
    width: 100%;
    height: 100%;
}
.odc-home-top-padding-style{
    padding: 0% 7% 0% 15%;
}
.odc-orderlist-header{
    // background-color: #dc0000;
    background-image:url("./../../../public/images/rsbg1.png");
    background-size: cover;
    width: 100%;
    padding: 2% 4% !important;
    // border-radius: 11px;
    color: white;
    height: 240px;
}
.line-height-order-li{
    line-height: 45px;
}
.odc-ord-list-status-btn{
    border: none;
    background-color: #02b32b;
    padding: 0% 4%;
    color: #fff;
    border-radius: 7px;
}
.odc-cart-table-menu-list thead{
    background-color: #000000;
}
.odc-menu-edit-textarea{
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
  }
.odc-cart-submit-btn-style {
    padding: 2% 11%;
    background: #c60800;
    border: none;
    border-radius: 56px;
    font-size: 20px;
    color: white;
}
.odc-cart-submit-btn-style:hover {
    padding: 2% 11%;
    background: #f0f8ff00;
    border: 2px solid #f00;
    border-radius: 56px;
    font-size: 20px;
    color: rgb(0, 0, 0);
}
.odc-checkout-btn-style{
    border-color: #ff0000;
    background: #ffffff;
    color: #f00;
    fill: #fff;
    padding: 2% 0%;
    font-weight: 600;

}
.odc-checkout-btn-style:hover{
    border-color: #ff0000;
    background: #000000;
    color: rgb(255, 255, 255);
    fill: #fff;
    padding: 2% 0%;
    font-weight: 600;

}
.odc-view-menu-btn {
    color: #000000 !important;
    background: #ffffff !important;
    border: none;
    border-radius: 32px;
    padding: 5px 35px;
    font-weight: 600;
    margin: 5% 6%;
    // box-shadow: 0px 0px 6px 2px rgba(2, 2, 2, 0.12);
}
.odc-view-menu-btn:hover {
    color: #c60800 !important;
    background: #ffffff !important;
    // border: 1px solid #c60800;
    border: none;
    border-radius: 32px;
    padding: 5px 35px;
    font-weight: 600;
    margin: 5% 6%;
    // box-shadow: 0px 0px 6px 2px rgba(2, 2, 2, 0.12);
}
.odc-cart-menu-list-item-style {
line-height: 40px;

}

.odc-cart-menu-list-item-style li{
    font-size: 20px;
    font-weight: 600;
    color: #4e4e4e;
}
.odc-addcart-menu-list-style th {
    background-color: #c60800;
    color: #ffffff;
    font-size: 20px;
}
.odc-vjoinbtn {
    padding: 1% 3%;
    border: 2px solid #c60800;
    background-color: white;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 700;
    color: #c60800;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}
.odc-testimonial-bg-style{

    background-color: #ffffff;
    /* filter: drop-shadow(rgba(0, 0, 0, 0.25) 5px 11px 13px); */
    box-shadow: 3px -1px 7px 1px rgb(0 0 0 / 38%);
    // margin: 2% 10%;
    padding: 10% 10%;
    border-radius: 1px 70px 1px 70px;
    margin-top: 8%;

}
.odc-testimonial-title{
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
.odc-clint-say-style{
    padding: 2% 9%;
}
//
.payment-container {
    text-align: center;
    margin: 100px auto;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .payment-method-select {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input-field {
    width: calc(100% - 20px);
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  .odc-link-txt-color{
    color: #000000;
  }
  .odc-home-date-input{
    width: 525px;
    border: none;
    background: none;
    /* padding-left: 1.5%; */
    padding: 2%;
  }
  .odc-home-date-input .react-datepicker-ignore-onclickoutside{
    width: 100%;
    border: none;
    background: none;
    /* padding-left: 1.5%; */
    padding: 2%;
  }
  .odc-footerlogo-copy{
    // display: grid;
    // align-items: center;
    // justify-content: center;
    text-align: center;
    color: rgb(255, 255, 255);
  }
  .odc-quiry-btn{
    padding: 1% 1%;
    border: none;
    border-radius: 7px;
    background-color: #e3f1ff;
    box-shadow: 7px 4px 5px 2px rgb(0 0 0 / 12%);
    font-weight: 600;
    color: #005199;
    display: block;
    text-align: center;
    margin-top: 5%;
    width: 50%;
  }
  .odc-quiry-btn:hover{
    padding: 1% 1%;
    border: none;
    border-radius: 7px;
    background-color: #000000;
    box-shadow: 7px 4px 5px 2px rgb(0 0 0 / 12%);
    font-weight: 600;
    color: #ffffff;
    display: block;
    text-align: center;
    margin-top: 5%;
    width: 50%;
  }
  .odc-adservice-view-btn{
    background-color: #c60800;
    padding: 1% 4%;
    border-radius: 7px;
    color: white;
    border: none;
  }
  .odc-details-orbtn{
    background-color: #28a745;
    border: none;
    padding: 2% 7%;
    border-radius: 7px;
    color: white;
  }
  .odc-details-paybtn{
    background-color: #a72828;
    border: none;
    padding: 2% 7%;
    border-radius: 7px;
    color: white;
  }
  .odc-bill-txt{
    font-weight: 400;
    font-size: 18px;
    text-align: right;
  }
  .odc-bill-txt1{
    font-weight: 400;
    font-size: 18px;
    text-align: left;
  }
  .dash-line1 {
    border-bottom: 2px dashed #686868;
    padding: 0px 1px;
}
.odc-food-type-img{
    width: 16px;
    position: absolute;
    padding-top: 7px;
    right: 150px;
}
.des-flex {
    display: flex;
}
.item-alinment-style {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #c60800;
}
    
.odc-lg-btn {
    font-weight: 600 !important;
    color: black;
    padding: 5% 9%;
    margin: 1% 5%;
    background-color: #ffc10700;
    border-radius: 1px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.39);
    border: 1px solid #000;
}
.odc-sg-btn {
    font-weight: 600 !important;
    color: white;
    padding: 5% 9%;
    margin: 1% 5%;
    background-color: #000000;
    border-radius: 1px;
    border: 1px solid #000;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.39);
}
.nav-menubar-inustry-icon1 {
    font-size: 42px !important;
    color: #c60800;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 36px;
}
.odc-checkout-address-edite{
    border: none;
    padding: 3px 20px;
    background-color: black;
    color: white;
    margin-top: 3%;
}
.odc-lg-btn {
    font-weight: 600 !important;
    color: black;
    padding: 5% 9%;
    margin: 1% 5%;
    background-color: #ffc10700;
    border-radius: 1px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.39);
    border: 1px solid #000;
}
.odc-sg-btn {
    font-weight: 600 !important;
    color: white;
    padding: 5% 9%;
    margin: 1% 5%;
    background-color: #000000;
    border-radius: 1px;
    border: 1px solid #000;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.39);
}
.password-icon-style1 {
    position: absolute !important;
    left: 58%;
    margin-top: 1.5% !important;
}

.sigin-page-style {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 2%;
}
.singin-center-style {
    background-color: #ffffffc7;
    // padding: 0% 10%;
    border-radius: 7px;
    background-image: url("../../../public/images/banners/lgbg2.png");
    background-size: 85% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 5% 30%;
    padding: 4% 8%;
    width: 100%;
    box-shadow: 8px 10px 6px #000000ad;
}
.singin-center-stylelg1 {
    background-color: #ffffffc7;
    // padding: 0% 10%;
    border-radius: 7px;
    background-image: url("../../../public/images/banners/lgbg2.png");
    background-size: 70% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 5% 30%;
    padding: 4% 8%;
    width: 100%;
    box-shadow: 8px 10px 6px #000000ad;
}
.singin-tl-style {
    text-align: center;
    color: #c60800;
    /* margin-left: 36%; */
    font-weight: 800;
    font-size: 35px;
}
.singin-rgbtn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    background-color: #4caf50;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 3px 0px #388e3c;
    transition: all 0.3s ease;
    margin-left: 35%;
}
.odcresend-btn {
    background-color: #f0f8ff00;
    border: none;
    color: #c60800;
    font-weight: 700;
}
.odc-dladdress-input {
    width: 100%;
    padding: 2% 2%;
    border: 2px solid #0078d2;
    border-radius: 7px;
}
.odc-dlbtn-style {
    margin: 3% 0%;
    border: none;
    background-color: #0078d2;
    padding: 2% 5%;
    border-radius: 7px;
    color: white;
    font-weight: 600;
}
.otp-btn {
    margin-top: 7%;
    background-color: #c60800;
    border: 3px solid #c60800;
    border-radius: 7px;
    padding: 2% 5%;
    font-size: 15px;
    font-weight: 800;
    color: white;
}
.otp-input-box {
    width: 32px;
    height: 32px;
    text-align: center;
    margin-right: 20px;
}
.otp-rebtn {
    margin-left: 3%;
    background-color: #0d00c6;
    border: 3px solid #0d00c6;
    border-radius: 7px;
    padding: 2% 5%;
    font-size: 15px;
    font-weight: 800;
    color: white;
}
.odc-adress-lg-input-style1{
    width: 100%;
    padding: 2%;
    border: none;
    background-color: aliceblue;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.address-input-container {
    position: relative;
  }
  
.address-icon {
    position: absolute;
    top: 50%;
    left: 93%;
    transform: translateY(-50%);
    color: #6e7275;
  }

  .odc-cart-slmenucard-style{
    padding: 1% 1%;
    border: none;
    border-radius: 7px;
    background-color: #e3f5ff;
    box-shadow: 7px 4px 5px 2px rgba(0, 0, 0, 0.12);
    font-weight: 600;
    /* color: #005199; */
    display: flex;
    text-align: center;
    align-items: center;
    margin-top: 2%;
    width: 100%;
  }
  .odc-star-review-btn-style{
    border: 1px solid #ffc107 ;
    background-color: #ffffff00;
    color: #ffc107;
    padding: 5px 12px;
    font-size: 18px;

   
  }
  .odc-star-review-btn-style:hover{
    border: none;
    background-color: #ffc107;
    color: #fff;
    padding: 5px 12px;
    font-size: 18px;
   
  }
  .odc-payment-detaile-worning{
    border: 2px solid orange;
    border-radius: 7px;
    margin-left: 2%;
    padding-left: 40px;
    padding-right: 20px;
    background-color: #ffffff;
  }
  .odc-payment-detaile-worning1{
    margin-top: 4%;
    border: 2px solid orange;
    border-radius: 7px;
   background-color: #ffc107;
  }
  .odc-quiry-btn-a{
    color: #005199;
  }
  .odc-quiry-btn-a:hover{
    color: #ffffff;
  }
  .review-btn{
    border: none;
    background-color: #000000b5;
    color:#fff;
  }
  .redio-pay-btn-style{
    padding: 1% 2%;
    border: 1px solid #b9b9b9d6;
    margin: 1% 0%;
   
  }
  .redio-pay-btn-style:hover{
    padding: 1% 2%;
    border: 1px solid #b9b9b9d6;
    margin: 1% 0%;
    background-color: #e9e9e92b;
  }
  .redio-pay-btn-style1{
    padding: 1% 2%;
    border: 2px solid #ffffffd6;
    margin: 1% 0%;
    color: #fff;
    background-color: #388e3c;
   
  }
  .minpay-input-style{
    padding: 1% 3%;
    border: 1px solid #c7c1c1;
    border-radius: 7px;
    box-shadow: 7px 4px 5px 2px rgba(0, 0, 0, 0.12);
    margin: 2% 0%;
  }
  .pay-notify-style{
    color: #000;
    width:480px !important;
  }
  .checkout-min-pay-filed{
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #c60800;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .odc-service-tabs-text-style12{
    color: #000;
  }
  .odc-service-tab-style1{
    display: flex;
  }
  .odc-head-logo{
    width: 45%;
  }
  .odc-service-tab-height{
    height: 570px;
     padding: 1% 10%
  }