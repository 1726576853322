/*
// .filter-categories
*/
@import "../variables";
@import "../mixins/direction";

$local-item-padding-left: 14px;

.filter-categories {
}
.filter-categories__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 18px;

    a {
        color: inherit;
        transition: color 0.15s;
    }
    a:hover {
        color: $link-hover-color;
    }
}
.filter-categories__item {
    padding: 6px 0;
    display: flex;

    & > a {
        flex-grow: 1;

        @include direction {
            #{$padding-inline-end}: 10px;
        }
    }
}
.filter-categories__item--parent {
    position: relative;

    @include direction {
        #{$padding-inline-start}: $local-item-padding-left;
    }
}
.filter-categories__item--current {
    font-weight: $font-weight-bold;
}
.filter-categories__item--child {
    position: relative;
    font-size: 15px;

    @include direction {
        #{$padding-inline-start}: $local-item-padding-left;
    }
}
.filter-categories__arrow {
    fill: $filter-title-default-arrow-color;
    position: absolute;
    top: 10px;

    @include direction {
        #{$inset-inline-start}: 0;
        transform: scaleX(1 * $transform-direction);
    }
}
.filter-categories__counter {
    font-size: 12px;
    line-height: 12px;
    padding-top: 4px;
    font-weight: $font-weight-normal;
    color: $card-font-muted-color;
}
