/*
// .widget-posts
*/
@import "../variables";
@import "../mixins/card";
@import "../mixins/direction";

.widget-posts {
    @include card();

    padding: 28px;

    @media (max-width: 479px) {
        padding: 20px;
    }
}
.widget-posts__list {
    padding-top: 10px;
}
.widget-posts__item {
    display: flex;

    & + & {
        margin-top: 22px;
    }
}
.widget-posts__image {
    width: 90px;
    flex-shrink: 0;
    border-radius: 1.5px;
    overflow: hidden;
    align-self: self-start;
    position: relative;

    @include direction {
        #{$margin-inline-end}: 16px;
    }

    ::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: $post-image-overlay-bg;
        opacity: 0;
        transition: opacity 0.6s;
        z-index: 1;
    }

    img {
        max-width: 100%;
        display: block;
    }

    &:hover {
        ::before {
            opacity: 1;
        }
    }
}
.widget-posts__info {
    margin-top: -2px;
}
.widget-posts__name {
    font-size: 15px;
    line-height: 18px;

    a {
        color: inherit;
        transition: color 0.2s;
    }
    a:hover {
        color: $link-hover-color;
    }
}
.widget-posts__date {
    margin-top: 2px;
    font-size: 13px;
    color: $card-font-muted-color;
}
