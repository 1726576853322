/*
// .widget-filters
*/
@import "../variables";
@import "../functions";
@import "../mixins/card";
@import "../mixins/direction";

@mixin local-offcanvas() {
    border: none;
    padding-top: 0;

    .widget-filters__title {
        display: none;
    }
    .widget-filters__item:first-child {
        border-top: none;
    }
    .widget-filters__item {
        border-width: 1px;
    }
}

.widget-filters {
    @include card();
    padding: $widget-shop-padding;
}
.widget-filters__item {
    padding: 8px $widget-shop-padding;
    margin: 0 (-$widget-shop-padding);
    border-bottom: 2px solid $card-border-color;

    &:first-child {
        border-top: 2px solid $card-border-color;
    }
}
.widget-filters__actions {
    padding-top: 24px;
    padding-bottom: 16px;

    & > .btn + .btn {
        @include direction {
            #{$margin-inline-start}: 8px;
        }
    }
}

.widget-filters--offcanvas--always {
    @include local-offcanvas;
}

.widget-filters--offcanvas--mobile {
    @media (max-width: breakpoint(md-end)) {
        @include local-offcanvas;
    }
}
