/*
// .account-nav
*/
@import "../variables";
@import "../functions";
@import "../mixins/card";
@import "../mixins/direction";

.account-nav {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
.account-nav__item a {
    display: block;
    color: $body-font-alt-color;
    font-size: 15px;

    &:hover {
        background-color: #f7f7f7;
    }
}

.lgtext {
    text-align: center;
}

.rg-popup-tit {
    text-align: center;
    color: rgb(255, 255, 255);
    margin-left: 36%;
    font-weight: 800;
    font-size: 35px;
}
@media (min-width: breakpoint(lg-start)) {
    .lginputtxt {
        width: 100%;
        margin-left: 0%;
        border-radius: 36px;
        border: 2px solid #d5d5d5;
        padding: 21px;
    }
    .account-nav {
        @include card;

        ul {
            padding-bottom: 18px;
        }
    }
    .account-nav__title {
        font-size: 20px;
        padding: 24px 26px 0;
        margin-bottom: 1rem;
    }
    .account-nav__item a {
        padding: 5px 26px;

        @include direction {
            #{$margin-inline-start}: -2px;
            #{$border-inline-start}: $card-border-width solid $card-border-color;
        }
    }
    .account-nav__item--active a {
        color: $body-font-color;
        font-weight: $font-weight-medium;

        @include direction {
            #{$border-inline-start}: $card-border-width solid $accent-color;
        }
    }
    .password-icon-style {
        position: absolute !important;
        left: 65%;
        padding-top: 1.5% !important;
    }
    .password-icon-style1 {
        position: absolute !important;
        left: 58%;
        margin-top: 1.5% !important;
    }
    .password-icon-style2 {
        position: absolute !important;
        left: 58%;
        margin-top: 0.3% !important;
    }
    .password-icon-style3 {
        position: absolute !important;
        left: 80%;
        margin-top: 2.5% !important;
    }
    .singin-center-style {
        background-color: #ffffffc7;
        // padding: 0% 10%;
        border-radius: 7px;
        background-image: url("../../../public/images/banners/lgbg2.png");
        background-size: 85% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 5% 30%;
        padding: 4% 8%;
        width: 100%;
        box-shadow: 8px 10px 6px #000000ad;
    }
    .singin-center-stylelg1 {
        background-color: #ffffffc7;
        // padding: 0% 10%;
        border-radius: 7px;
        background-image: url("../../../public/images/banners/lgbg2.png");
        background-size: 70% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 5% 30%;
        padding: 4% 8%;
        width: 100%;
        box-shadow: 8px 10px 6px #000000ad;
    }
    .otp-resp {
        width: 100%;
    }
    .mainhome-slid123 {
        position: relative;
        width: 400px;
        margin-left: 1px;
        height: 300px;
    }
    //

    .flip-card {
        width: 400px; /* Adjust this width to fit your image size */
        height: 360px; /* Adjust this height to fit your image size */
        perspective: 1000px;
    }

    .main-about-img-style {
        height: 430px;
        margin-top: 7%;
        margin-bottom: 15%;
        width: 478px;
        // margin-left: 7%;
    }

    //
    .sliding-line1 {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 63%;
        left: 16%;
    }
    .sliding-line2 {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 26%;
        left: 16%;
    }
    .sliding-line3 {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 63%;
        left: 61%;
    }
    .sliding-line4 {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 27%;
        left: 61%;
    }

    .slide-in5 {
        opacity: 1;
        transform: translateY(0);
    }

    .mobile-bg-color {
        text-align: center;
        background-color: rgb(0 0 0 / 74%);
        height: 100%;
        width: 100%;
        color: #fff;
    }

    //

    .sliding-text {
        opacity: 0;
        transform: translateX(-100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 33%;
        left: -23%;
        color: #ffc107;
        font-size: 20px;
        font-weight: 700;
    }

    .slide-in {
        opacity: 1;
        transform: translateX(0);
    }

    .odc-service-tab-style1
        .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
        display: flex;
    }

    .sliding-text1 {
        opacity: 0;
        transform: translateX(-100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 70%;
        left: -20%;
        color: #ffc107;
        font-size: 20px;
        font-weight: 700;
    }
    .slide-in1 {
        opacity: 1;
        transform: translateX(0);
    }

    .sliding-text2 {
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 70%;
        left: 61%;
        color: #ffc107;
        font-size: 20px;
        font-weight: 700;
    }

    .slide-in2 {
        opacity: 1;
        transform: translateX(0);
    }

    .sliding-text3 {
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 34%;
        left: 61%;
        color: #ffc107;
        font-size: 20px;
        font-weight: 700;
    }

    .slide-in3 {
        opacity: 1;
        transform: translateX(0);
    }

    .main-about-title-icon {
        width: 11%;
        height: 20%;
        position: absolute;
        left: 0%;
        top: 5%;
    }

    .odc-header-img-width {
        width: 644px;
        height: 100%;
    }
    .odc-header-meimg-width {
        width: 100%;
        height: 100%;
    }

    .service-tab123 {
        padding: 0% 10%;
    }
    .odc-service-tab-style1 {
        display: flex;
    }
    .odc-head-logo {
        width: 45%;
    }
    .odc-service-tab-height {
        height: 570px;
        padding: 1% 10%;
    }
    .odc-mb-aline {
        padding-top: 0%;
    }
}
@media (max-width: breakpoint(sm-end)) {
    // .odc-service-tab-style1 .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    //   display: grid !important;
    // }
    .mb-place-service {
        display: none;
    }
}

@media (max-width: breakpoint(md-end)) {
    .mb-place-service {
        display: none;
    }
    .lginputtxt {
        width: 100%;
        margin-left: 0%;
        border-radius: 36px;
        border: 2px solid #d5d5d5;
        padding: 21px;
    }
    .account-nav__title {
        display: none;
    }
    .account-nav {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        ul {
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            width: 100%;
            border-bottom: $card-border-width solid $card-border-color;
        }
    }
    .account-nav__item a {
        padding: 8px 16px;
        margin-bottom: -2px;
        border-bottom: $card-border-width solid $card-border-color;
    }
    .account-nav__item--active a {
        color: $body-font-color;
        font-weight: $font-weight-medium;
        border-color: $accent-color;
    }
    .password-icon-style {
        position: absolute !important;
        left: 72%;
        padding-top: 3.5% !important;
    }
    .password-icon-style1 {
        position: absolute !important;
        left: 72%;
        padding-top: 6.5% !important;
    }
    .password-icon-style2 {
        position: absolute !important;
        left: 72%;
        padding-top: 3.5% !important;
    }
    .password-icon-style3 {
        position: absolute !important;
        left: 72%;
        padding-top: 3.5% !important;
    }
    .singin-center-style {
        background-color: #ffffffc7;
        // padding: 0% 10%;
        border-radius: 7px;
        background-image: url("../../../public/images/banners/lgbg2.png");
        background-size: 85% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 2% 2%;
        padding: 4% 8%;
        width: 100%;
        box-shadow: 8px 10px 6px #000000ad;
    }
    .singin-center-stylelg1 {
        background-color: #ffffffc7;
        // padding: 0% 10%;
        border-radius: 7px;
        background-image: url("../../../public/images/banners/lgbg2.png");
        background-size: 90% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 2% 2%;
        padding: 4% 8%;
        width: 100%;
        box-shadow: 8px 10px 6px #000000ad;
    }
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        width: 100%;
    }

    .mainhome-slid123 {
        position: relative;
        width: 300px;
        margin-left: 1px;
        height: 300px;
    }

    .mobile-bg-color {
        text-align: center;
        background-color: rgb(0 0 0 / 74%);
        height: 100%;
        width: 100%;
        color: #fff;
        margin-left: 0.1%;
    }

    //

    .flip-card {
        width: 300px; /* Adjust this width to fit your image size */
        height: 360px; /* Adjust this height to fit your image size */
        perspective: 1000px;
    }

    .main-about-img-style {
        height: 320px;
        margin-top: 7%;
        margin-bottom: 15%;
        width: 300px;
        margin-left: 0%;
    }

    .mb-order-steps {
        width: 75%;
        margin-top: 100px;
        margin-bottom: 100px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        margin-left: 15%;
    }

    //

    .sliding-line1 {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 63%;
        left: 4%;
    }
    .sliding-line2 {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 26%;
        left: 4%;
    }
    .sliding-line3 {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 63%;
        left: 54%;
    }
    .sliding-line4 {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 27%;
        left: 54%;
    }

    .slide-in5 {
        opacity: 1;
        transform: translateY(0);
    }

    //
    .sliding-text {
        opacity: 0;
        transform: translateX(-100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 47%;
        left: -58%;
        color: #ffc107;
        font-size: 13px;
        font-weight: 700;
    }

    .slide-in {
        opacity: 1;
        transform: translateX(0);
    }

    .mb-style-mt {
        margin-top: 20%;
    }

    .sliding-text1 {
        opacity: 0;
        transform: translateX(-100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 82%;
        left: -60%;
        color: #ffc107;
        font-size: 14px;
        font-weight: 700;
    }
    .sliding-text1 img {
        width: 6%;
    }
    .slide-in1 {
        opacity: 1;
        transform: translateX(0);
    }

    .sliding-text2 {
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 84%;
        left: 61%;
        color: #ffc107;
        font-size: 13px;
        font-weight: 700;
    }

    .slide-in2 {
        opacity: 1;
        transform: translateX(0);
    }

    .sliding-text3 {
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        position: absolute;
        bottom: 48%;
        left: 66%;
        color: #ffc107;
        font-size: 13px;
        font-weight: 700;
    }

    .slide-in3 {
        opacity: 1;
        transform: translateX(0);
    }

    .main-about-title-icon {
        display: none;
    }

    .slide-text {
        position: absolute;
        bottom: 60%;
        left: 10%;
        transform: translateY(50%);
        color: #fff;
        font-size: 28px;
        font-weight: 900;
        width: 87%;
    }

    .slide-text-p {
        position: absolute;
        bottom: 21%;
        left: 10%;
        transform: translateY(50%);
        color: #fff;
        font-size: 15px !important;
        font-weight: bold;
    }

    .re-btn {
        position: absolute;
        bottom: 20%;
        left: 10%;
        background-color: #c60800;
        padding: 1% 2%;
        color: aliceblue;
        border: none;
        border-radius: 5px;
        font-weight: 700;
    }

    .pp-services {
        text-align: center;
        color: #fff;
        padding: 0% 2%;
    }
    .ab-service1 {
        font-size: 38px;
        margin-left: 3%;
    }
    .footer-b {
        width: 40%;
    }
    .footer-hb {
        padding: 5% 0%;
    }
    .intro-homebanner-style {
        color: rgb(255, 255, 255);
        padding-top: 20%;
        width: 100%;
        padding-bottom: 20%;
        text-align: start;
        padding-left: 0%;
    }

    .card-bg-style {
        border-radius: 1rem !important;
        background-color: #2f3437;
        color: #ffffff;
        border: none !important;
        margin-top: 10%;
    }
    .app-txt-top-style {
        margin-top: 15%;
        padding: 0% 1%;
    }

    .m3-home-header-component {
        grid-template-columns: 1fr;
    }
    .m3-home-header-content {
        margin-left: 5%;
        margin-right: 5%;
    }

    .mg-10-tp {
        margin-top: 10%;
    }
    .lg-style-logo-img-w {
        width: 70%;
    }
    .lg-style-txt-mt {
        color: red;
        margin-top: 0%;
    }
    .blog-view-btn {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 10% 0%;
    }

    .card-blog-top-style {
        width: 100%;
        height: 210px;
        border-radius: 1rem;
        overflow: hidden;
    }

    .sv-card-images {
        height: 210px;
        margin-left: 1px;
        position: relative;
        overflow: hidden;
    }
    .m3-futures-style {
        margin-top: 15%;
        width: 100%;
    }
    .main-about-img-style-moniter {
        height: 250px;
        margin-top: 7%;
        margin-bottom: 15%;
        width: 310px;
    }
    .faqs-vendor-style {
        background-image: linear-gradient(154deg, #ff2b2b, #7a0500);
        border-radius: 1rem !important;
        color: #ffffff;
        border: none !important;
        padding: 8% 0% 0% 0%;
        margin-top: 12%;
        margin-bottom: 5%;
        width: 100%;
        position: relative;
        left: 0%;
    }
    .itemcenter123 {
        left: 0%;
        position: relative;
    }
    .faqs-titel-text-style {
        text-align: center;
        margin: 18% 0%;
    }
    .faq-section {
        overflow-y: hidden;
        height: 100%;
        width: 100% !important;
        border: 1px solid #fff;
        border-radius: 7px;
        margin: 2% 0%;
    }
    .faq-section:hover {
        overflow-y: hidden;
        height: 100%;
        width: 100%;
        border: 2px solid #c60800;
        border-radius: 7px;
        margin: 2% 0%;
    }
    .app-playstore-width {
        width: 45%;
    }
    .m3-main-miles-img-style {
        height: 235px;
        margin-top: 7%;
        margin-bottom: 15%;
        width: 325px;
    }

    .m3slider-container img {
        width: 45%;
    }
    .mobile-view-m3 {
        display: none;
    }
    .h1-m3slider-text {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 2.5rem;
    }
    .mobile-br {
        padding: 4%;
    }
    .m3-main-text-pad-style {
        padding: 0% 0%;
    }
    .m3-h2-text-style {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.2;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
    }
    .m3-bl-bottom {
        margin-bottom: 11%;
    }

    .m3-main-img {
        width: 100% !important;
        height: 100%;
        border-radius: 10px;
        border: 2px solid #c60800;
    }
    .m3-main-img:hover {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 2px solid #f9e830;
    }

    .odc-header-img-width {
        width: 100%;
        height: 100%;
    }
    .odc-header-meimg-width {
        width: 100%;
        height: 100%;
    }

    .service-tab123 {
        padding: 0% 0%;
    }
    .pay-notify-style {
        color: #000;
        width: 355px !important;
    }

    .odc-cart-slmenucard-style {
        padding: 7% 1%;
        border: none;
        border-radius: 7px;
        background-color: #e3f5ff;
        box-shadow: 7px 4px 5px 2px rgba(0, 0, 0, 0.12);
        font-weight: 600;
        /* color: #005199; */
        display: block;
        text-align: center;
        align-items: center;
        margin-top: 2%;
        width: 100%;
    }
    .odc-service-tab-style1
        .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
        display: grid !important;
    }
    .odc-head-logo {
        width: 100%;
    }
    .odc-service-tab-height {
        height: auto;
        padding: 1% 10%;
    }
    .odcclient-style {
        width: 340px;
    }
    .nice-dates-popover {
        width: 280px;
        margin-left: -7%;
    }
    .odc-mb-aline {
        padding-top: 40%;
    }
}

.lgtext-title {
    line-height: 36px !important;
    padding-top: 0px !important;
    margin-top: -29px;
    font-size: 25px !important;
}
.lgname {
    color: red;
    font-size: 38px;
    font-weight: 800;
    line-height: 79px;
}
.rgbtn {
    padding: 6px 51px;
    background-color: #c60800;
    color: white;
    font-weight: 600;
    font-size: 19px;
    border: 2px solid white;
    border-radius: 37px;

    &:hover {
        padding: 6px 51px;
        background-color: rgb(255, 255, 255);
        color: rgb(255, 0, 0);
        font-weight: 600;
        font-size: 19px;
        border: 2px solid rgb(255, 0, 0);
        border-radius: 37px;
    }
}

.rgbtn1 {
    padding: 9px 58px;
    background-color: #c60800;
    color: white;
    font-weight: 0;
    font-size: 19px;
    border: 2px solid white;
    border-radius: 7px;
    margin-right: 30%;

    &:hover {
        padding: 9px 58px;
        background-color: rgb(255, 255, 255);
        color: rgb(255, 0, 0);
        font-weight: 0;
        font-size: 19px;
        border: 2px solid rgb(255, 0, 0);
        border-radius: 7px;
    }
}
.rgbtn2 {
    padding: 9px 23px;
    background-color: #c60800;
    color: white;
    font-weight: 0;
    font-size: 19px;
    // border: 2px solid black;
    border-radius: 7px;
    // margin-right: 30%;
    text-decoration: none;

    &:hover {
        padding: 9px 23px;
        background-color: rgb(255, 255, 255);
        color: rgb(255, 0, 0);
        font-weight: 0;
        font-size: 19px;
        border: 2px solid rgb(255, 0, 0);
        border-radius: 7px;
        // margin-right: 30%;
        text-decoration: none !important;
    }
}
.fiteam_list {
    line-height: 33px !important;
    font-size: 16px !important;
    font-family: unset;
    font-weight: 500;
}

.pop_head {
    border-bottom: none;
    background-color: #c60800;
    border-top-left-radius: calc(-2.7rem - 1px);
    border-top-right-radius: calc(-2.7rem - 1px);
}
.logbtn {
    padding: 9px 45px;
    background-color: rgb(255, 255, 255);
    color: rgb(255, 0, 0);
    font-weight: 600;
    font-size: 19px;
    border: 2px solid rgb(255, 0, 0);
    border-radius: 37px;

    &:hover {
        padding: 9px 45px;
        background-color: #c60800;
        color: white;
        font-weight: 600;
        font-size: 19px;
        border: 2px solid white;
        border-radius: 37px;
    }
}

.banner_btn {
    padding: 10px 44px;
    background-color: #ffffff00;
    color: white;
    font-weight: 600;
    font-size: 19px;
    border: 2px solid red;
    border-radius: 6px;

    &:hover {
        padding: 10px 44px;
        background-color: #c60800;
        color: white;
        font-weight: 600;
        font-size: 19px;
        border: 2px solid rgb(255, 255, 255);
        border-radius: 6px;
    }
}
.cls-btn {
    color: rgb(255, 255, 255);
    border: 2px solid #c60800;
    border-radius: 63px;
    background-color: #f0f8ff00;
    font-size: 15px;
    font-weight: 700;
}
.rg-info-txt {
    color: #2c2c2c;
    text-align: center;
    font-weight: 500;
}
