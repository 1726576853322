/*
// .page-header
*/
@import "../variables";
@import "../functions";

.page-header__container {
    padding-bottom: 32px;
}
.page-header__breadcrumb {
    padding-top: 16px;
}
.page-header__title {
    padding-top: 6px;

    h1 {
        margin-bottom: 0;
    }
}

@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .page-header__title {
        padding-top: 4px;

        h1 {
            margin-bottom: 0;
        }
    }
    .mbwidth {
        width: 100% !important;
    }

    .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
        width: 55%;
        margin-left: 14%;
    }
    .home-slide-carousel-style {
        height: 155px;
        margin-top: 0px;
    }
    .mrlft23{
        margin-left: 0%; 
     }
     .typography {
      
        margin-bottom: 10%;
    }
    .block-features--layout--classic {
        margin-top: 0px !important;
    }
    .lginputtxt {
        width: 100% !important;
        margin-left: 0% !important;
      
    }
    .width-50 {
       
        margin-left: 23%;
        margin-top: 2%;
    }
    .calunder-data {
      
        width: 100% !important;
      
    }
    .mbcard{
        display: inline-flex;
        width: 100%;
    }
    .menu-veg-non {
        width: 15%;
        height: 15%;
    }
    .count-brt {
        border: 2px solid red;
        border-radius: 38px;
        width: 100%;
    }
    
    .package-counter {
        margin-left: 0%;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        margin-top: 28px;
    }

    .ord-btn-menu {
        width: 100%;
        
    }
    .d-flex {
        display: block !important;
    }
    .mb-ml-10{
        margin-left: 30%;
    }
    .mb-mt-30{
        margin-top: 25%;
    }
    .menuitem-list-style12{
        min-width: 0px;
    }
    .order-details-style {
        padding: 2px 22px 24px;
        color: #2a2a2a;
        font-size: 19px;
        font-weight: 700;
    }
    .veg-details-img {
        width: 8%;
        height: 2%;
        margin-right: 14px;
    }
    .weight-btn {
        background-color: #000000 !important;
        color: white !important;
        width: 100% !important;
    }
    .stywdth50 {
        width: 60% !important;
    }
    .main-menu-d-flex.align-items-center {
        left: 30% !important;
        padding-bottom: 7px;
    }
}


@media (max-width: breakpoint(sm-end)) {
    .page-header__container {
        padding-bottom: 24px;
    }
    .page-header__title {
        padding-top: 2px;

        h1 {
            margin-bottom: 0;
            font-size: 30px;
        }
    }
    .mbwidth {
        width: 100% !important;
    }
    .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
        width: 55%;
        margin-left: 14%;
    }
    .home-slide-carousel-style {
        height: 155px;
        margin-top: 0px;
    }
    .mrlft23{
        margin-left: 0%; 
     }
     .typography {
      
        margin-bottom: 10%;
    }
    .block-features--layout--classic {
        margin-top: 0px !important;
    }
    .lginputtxt {
        width: 100% !important;
        margin-left: 0% !important;
       
    }
    .width-50 {
       
        margin-left: 23%;
        margin-top: 2%;
    }
    .calunder-data {
      
        width: 100% !important;
      
    }
    .mbcard{
        display: inline-flex;
        width: 100%;
    }
    .menu-veg-non {
        width: 15%;
        height: 15%;
    }
    .count-brt {
        border: 2px solid red;
        border-radius: 38px;
        width: 100%;
    }
    
    .package-counter {
        margin-left: 0%;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        margin-top: 28px;
    }
    .ord-btn-menu {
        width: 100%;
        
    }
    .mb-ml-10{
        margin-left: 30%;
    }
    .mb-mt-30{
        margin-top: 25%;
    }
    .d-flex {
        display: block !important;
    }
    .menuitem-list-style12{
        min-width: 0px;
    }
    .order-details-style {
        padding: 2px 22px 24px;
        color: #2a2a2a;
        font-size: 19px;
        font-weight: 700;
    }
    .veg-details-img {
        width: 8%;
        height: 2%;
        margin-right: 14px;
    }
    .weight-btn {
        background-color: #000000 !important;
        color: white !important;
        width: 100% !important;
    }
    .stywdth50 {
        width: 60% !important;
    }
    .main-menu-d-flex.align-items-center {
        left: 30% !important;
        padding-bottom: 7px;
    }
}
