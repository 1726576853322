/*
// .filters-button
*/
@import "../variables";
@import "../mixins/direction";

.filters-button {
    display: flex;
    align-items: center;
    padding: 0 9px;
    height: 32px;
    border: 1px solid $filters-button-default-border-color;
    border-radius: 2px;
    background: $filters-button-default-bg;
    cursor: pointer;
    font-family: inherit;
    font-weight: 500;
    font-size: 14px;
    color: inherit;

    &:focus {
        outline: none;
    }
    &:hover {
        background: $filters-button-hover-bg;
        border-color: $filters-button-hover-border-color;
    }
}
.filters-button__icon {
    fill: $filters-button-icon-color;

    @include direction {
        #{$margin-inline-end}: 8px;
    }
}
.filters-button__counter {
    display: block;
    height: 20px;
    font-size: 13px;
    font-weight: 500;
    padding: 2px 5px 0;
    border-radius: 2px;
    line-height: 16px;
    background: $filters-button-counter-bg;
    color: $filters-button-counter-font-color;

    @include direction {
        #{$margin-inline-start}: 12px;
        #{$margin-inline-end}: -4px;
    }
}
