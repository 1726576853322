/*
// .product-gallery
*/
@import "../variables";
@import "../mixins/direction";

.product-gallery {
}
.product-gallery__featured {
    position: relative;
    box-shadow: $product-gallery-item-default-shadow;
    padding: 2px;
    border-radius: $product-gallery-item-border-radius;

    .product-image {
        padding: 20px;
    }
    .product-image__body:focus {
        outline: none;
    }
}
.product-gallery__zoom {
    position: absolute;
    cursor: pointer;
    padding: 11px;
    border: none;
    z-index: 2;
    top: 0;
    background: $product-gallery-zoom-button-normal-bg-color;
    fill: $product-gallery-zoom-button-normal-icon-color;
    transition: background 0.2s, fill 0.2s;

    @include direction {
        #{$inset-inline-end}: 0;
        #{$border-start-end-radius}: $product-gallery-item-border-radius + 0.5px;
        #{$border-end-start-radius}: 2px;
        transform: scaleX($transform-direction);
    }

    &:focus {
        outline: none;
    }
    &:hover {
        background: $product-gallery-zoom-button-hover-bg-color;
        fill: $product-gallery-zoom-button-hover-icon-color;
    }
    &:active {
        background: $product-gallery-zoom-button-active-bg-color;
        fill: $product-gallery-zoom-button-active-icon-color;
    }
}
.product-gallery__carousel {
    margin-top: 16px;

    .slick-list {
        margin: 0 -5px;
    }
    .slick-slide {
        padding: 0 5px;
    }
    .slick-track {
        @include direction {
            #{$margin-inline-start}: 0;
        }
    }
}
.product-gallery__carousel-item {
    vertical-align: middle;
    width: 100%;
    cursor: pointer;
    display: block;
    box-shadow: $product-gallery-item-default-shadow;
    padding: 12px;
    border-radius: $product-gallery-item-border-radius;
    border: none;
    background: transparent;

    &:focus {
        outline: none;
    }
}
.product-gallery__carousel-item--active {
    box-shadow: $product-gallery-item-active-shadow;
}
