.bdy-txt-color {
    color: #000000 !important;
}
.container12 {
    position: relative;
    width: 100%;
    margin-top: -4%;
}

.image {
    display: block;
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: #000000a6;
}

.container12:hover .overlay {
    opacity: 1;
}

.icon {
    color: white;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.fa-user:hover {
    color: #eee;
}
//
.maintex {
    display: block;
    /* justify-content: center; */
    /* align-items: center; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 10px;
}
.button-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
}

//
.spin {
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.spin:hover {
    animation-play-state: paused;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.spin1 {
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.spin1:hover {
    animation-play-state: paused;
}

@keyframes spin1 {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(-0deg);
    }
}
.search-input{
    border-radius: 30px;
    border: 2px solid #c60800;
    padding: 7px;
    padding-right: 62px;
    padding-left: 20px;
    margin-top: 0px;
}
.search-input1 {
    border-radius: 30px;
    border: 2px solid #c60800;
    padding: 7px;
    padding-right: 62px;
    padding-left: 20px;
    margin-top: 70px;
    float: right;
    z-index: 1;
    position: inherit;
}
.search-input-icon {
    position: absolute;
    left: 57%;
    top: 4%;
    padding: 7px;
    border-radius: 50%;
    border: none;
    background-color: #c60800;
    color: white;
}
//Rg input
.rg-txt-input-style {
    width: 100% !important;
    border-radius: 11px !important;
    border: 2px solid #d5d5d5 !important;
    padding: 21px !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 30%;
    padding: 20px;
}
.send-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    background-color: #4caf50;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 3px 0px #388e3c;
    transition: all 0.3s ease;
}

.send-button:hover {
    background-color: #388e3c;
    box-shadow: 0px 3px 0px #1b5e20;
    transform: translateY(-2px);
}

.stepper {
    padding: 10px;
    background-color: #2196f3;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
}

.stepper.animate {
    animation-name: stepper-animation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes stepper-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
//
.heading_container h2 {
    font-size: 2.5rem;
    position: relative;
    margin-bottom: 3% !important;
    font-weight: 800;
    padding-bottom: 7px;
    text-align: center;
}
.mg24 {
    margin-top: 24%;
    height: auto !important;
}
.search-input-icon12 {
    position: absolute;
    left: 96.5%;
    top: 4.8%;
    padding: 7px;
    border-radius: 50%;
    border: none;
    background-color: #c60800;
    color: white;
}
.heading_container h2::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 75px;
    height: 5px;
    background-color: #dc0000;
}
//

.fade12 {
    opacity: 0;
    animation: fade12-in-out 1s ease-in-out infinite;
}

.fade12.active {
    animation: none;
    opacity: 1;
}

@keyframes fade12-in-out {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.d-flex {
    display: flex;
}
.star-rating-tab {
    background-color: #fdd200;
    margin: 0px 10px;
    padding: 7px 15px;
    color: white;
    border-radius: 30px;
    display: flex;
    align-items: center;
    width: 50%;
}
.rest-image-wt {
    width: 100%;
    height: 40% !important;
}
.menu-icon-style {
    padding-right: 10%;
    font-size: 32px !important;
    color: #dc0000;
}
.cdbd-style {
    height: 230px;
    text-align: start !important;
}
.shadow-on {
    border: none !important;
    box-shadow: none !important;
    height: 600px !important;
    &:hover {
        border: 2px solid #f0f0f0 !important;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
}
.txt-center {
    text-align: center !important;
}
.vl {
    border-left: 2px solid rgb(79, 85, 79);
    height: 500px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
}
.rs-name-txt {
    font-weight: 700 !important;
    color: #1d5600;
    font-size: 25px;
    margin: 15% 0% 10% 0%;
    text-align: center;
}
.menu-type {
    text-align: center !important;
    display: block !important;
}
.menu-search-bar-style {
    float: right;
    // position: absolute;
    // margin-top: -69px;
    // margin-left: 80%;
}
.rating-box {
    width: 100%;
    background-color: white;
    // border: 1px solid #bdbdbd59;
    border-radius: 4px;
    // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.041) ;
    text-align: center;
    // padding-bottom: 1px;
}
.box-shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.menu-cart-add-style {
    color: #000;
    display: flex;
}
.menu-cart-add-style1 {
    color: #000;
}
.rating_p {
    padding-top: 15px;
    padding-bottom: 0px;
    font-size: 17px;
    font-weight: 600;
}
.rating_star {
    padding-right: 10%;
    color: #ffc107;
    font-size: 35px !important;
}
.resturent_header{
    // background-color: #dc0000;
    background-image:url("../../../../public/images/rsbg2.png");
    background-size: cover;
    width: 100%;
    padding: 2% 4% !important;
    border-radius: 11px;
    color: white;
}
.rest-menusearch-btn{
    background-color: #c60800 !important;
    /* margin: 0px 25px; */
    float: right;
    margin-top: 6%;
    z-index: 1;
}
ul,li{
    list-style: none !important;
}
.service-checkbox-style{
    font-weight: 600;
    font-size: 20px;
    margin-right:4%;
}
.add-menu-btn{
    color: white !important;
    background: #00821a !important;
    /* border: 2px solid black; */
    border-radius: 32px;
    padding: 10px 35px;
    font-weight: 600;
    margin: 8% 1%;
}
.add-menu-input-style{
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
}
.add-menu-inputfiled-style{
    padding: 7px;
    border-radius: 14px;
    margin: 0% 1%;
    border: 2px solid #0473d3;
    box-shadow: 0 0.5rem 1rem rgb(0 75 243 / 16%) !important;

}
.location-sub-btn{
    // left: -33%;
    border-radius: 22px !important;
    padding: 10px 25px !important;
    background-color: #c60800 !important;
    margin-bottom: 5% !important;
}
.tab-txt-changes{
    font-size: 17px;
    line-height: 35px;
    font-weight: 500;
    margin-top: 10%;
}
.hero {
    position: relative;
  }

  .hero .text {
    color: rgba(255, 255, 255, 0.8);
  }
  .hero .text h2 {
    color: #ffffff;
    font-size: 30px;
    margin-bottom: 0;
  }

  .container123 {
    position: relative;
    width: 100%;
  }
  
  .steps-image-style {
    width: 70%;
    height: 270px;
  }
  
  .overlay1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000000b7;
  }
  
  .container123:hover .overlay1 {
    opacity: 1;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 25%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .text1 {
    color: #484848;
    font-size: 17px;
    font-weight: 500;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    text-align: revert;
    /* width: 85%; */
    padding: 0% 5%;
  }
  .vjoinbtn{
    padding: 3% 12%;
    border: 2px solid #c60800;
    background-color: white;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 700;
    color: #c60800;
    // filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  }
  .vjoinbtn:hover{
    padding: 3% 12%;
    border: 2px solid #c60800;
    background-color: #c60800;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    // filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  }
  .vjoinbtn1{
    padding: 1% 14%;
    border: 2px solid #2f6e3a;
    background-color: white;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 700;
    color: #2f6e3a;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  }
  .vjoin-cart-style{
    background-color: #FFE1FC;
    border-radius: 7px;
    margin: 5px 40px !important;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    text-align: center;
    padding-bottom: 4%;
    // height: 215px;
  }
  .vjoin-cart-style1{
    background-color: #cefff8;
    border-radius: 7px;
    margin: 5px 40px !important;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    text-align: center;
    padding-bottom: 4%;
    // height: 215px;
  }
  .vjoin-txtarea-style{
    color: rgb(110, 47, 105);
    margin-top: 6% !important;
  }
  .vjoin-txtarea-style1{
    color: rgb(47, 110, 58);
    margin-top: 6% !important;
  }
  .vendor-rg-txt{
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    color: black;
  }
//   .slide-in-left {
//     opacity: 0;
//     transform: translateX(-100%);
//     animation: slide-in-left 1s forwards;
//   }
  
//   @keyframes slide-in-left {
//     0% {
//       opacity: 0;
//       transform: translateX(-100%);
//     }
//     100% {
//       opacity: 1;
//       transform: translateX(0);
//     }
//   }
/* Define the animation keyframes */
@keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Apply the animation to the element */
  .slide-in-left {
    animation-name: slideInLeft;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  .DatePicker {
    width: 100%;
    margin-top: 4%;
}
input.DatePicker__input.-ltr {
    width: 100%;
    padding: 2%;
    border: none;
    text-align: justify;
    font-size: 17px;
    background-color: aliceblue;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.service-tab123{
    padding: 0% 10% !important;
}
.testimonial .testimonial_content{
    width: 100%;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .service-tab123{
        padding: 0% 0% ;
      }

}

@media (min-width: 1280px) {
    .service-tab123{
        padding: 0% 0% ;
      }
}

/* Styles for screens between 1024px and 1279px */

@media (min-width: 1024px) and (max-width: 1279px) {
    .service-tab123{
        padding: 0% 0% ;
      }
}

/* Styles for screens between 768px and 1023px */

@media (min-width: 768px) and (max-width: 1023px) {
    .service-tab123{
        padding: 0% 0% ;
      }
}

/* Styles for screens up to 767px */

@media (max-width: 767px) {
    .service-tab123{
        padding: 0% 0% ;
      }
}