/*
// .widget
*/
@import "../variables";

.widget {
}
.widget__title {
    margin-bottom: 20px;
    font-size: 20px;
}
