/*
// .block
*/
@import "../variables";
@import "../functions";

.block {
    margin-bottom: $block-margin-bottom;
}
.block--highlighted {
    padding: 50px 0 60px;
    background: $block-highlighted-bg;
}
.blocl__bg {
    background: #000000d6 !important;
}

@media (max-width: breakpoint(md-end)) {
    .block {
        margin-bottom: $block-margin-bottom-md;
    }
}
