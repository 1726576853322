/*
// .input-range
*/
@use "sass:math";
@import "../variables";

$local-track-height: 6px;
$local-handle-size: 16px;
$local-height: math.max($local-track-height, $local-handle-size);
$local-handle-inner-size: 6px;

.input-range {
    height: $local-height;
}
.input-range__track {
    height: $local-track-height;
    border-radius: $local-track-height * 0.5;
    transition: none;
}
.input-range__track--background {
    margin-top: -($local-track-height * 0.5);
}
.input-range__track--active {
    background: $nouislider-connect-color;
}
.input-range__slider {
    width: $local-handle-size;
    height: $local-handle-size;
    border-radius: $local-handle-size * 0.5;
    margin-top: -(($local-handle-size + $local-track-height) * 0.5);
    margin-left: -(($local-handle-size) * 0.5);
    border: none;
    background: $nouislider-border-color;
    box-shadow: 0 0 0 0 rgba($nouislider-border-color, 0);
    transition: box-shadow 0.2s;

    &:after {
        display: block;
        content: "";
        position: absolute;
        width: $local-handle-inner-size;
        height: $local-handle-inner-size;
        margin: ($local-handle-size - $local-handle-inner-size) * 0.5;
        border-radius: $local-handle-inner-size * 0.5;
        background: $nouislider-handle-color;
        transition: transform 0.2s;
    }
}
.input-range__slider:focus,
.input-range__slider:active {
    outline: none;
    box-shadow: 0 0 0 3px rgba($nouislider-border-color, 0.3);

    &:after {
        transform: scale(0);
    }

    transform: none;
}
.input-range__slider-container {
    transition: none;
}
.input-range__label {
    display: none;
}
