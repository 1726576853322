/*
// .order-header
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.order-header {
    padding: 1.625rem 2rem 1.375rem;

    &:after {
        display: block;
        content: "";
        clear: both;
    }
}
.order-header__actions {
    margin-top: -1px;

    @include direction {
        float: $inline-end;
    }
}
.order-header__title {
    border-bottom: 2px solid #e5e5e5;
    padding-bottom: 4px;
    margin-bottom: 0;

    @include direction {
        #{$padding-inline-end}: 3.5rem;
        float: $inline-start;
    }
}
.order-header__subtitle {
    color: $card-font-alt-color;
    font-size: 14px;
    line-height: 1.375;
    clear: both;
    padding-top: 12px;

    mark {
        padding: 0;
        color: $card-font-color;
        background: transparent;
        font-weight: $font-weight-medium;
    }
}

@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .order-header {
        padding: 1.375rem 1.5rem 1.25rem;
    }
}
@media (max-width: breakpoint(sm-end)) {
    .order-header {
        padding: 1.125rem 1.375rem 1rem;
    }
}
@media (max-width: 440px) {
    .order-header__actions {
        margin-bottom: 0.875rem;

        @include direction {
            float: none;
        }
    }
}
