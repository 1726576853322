/*
// .site
*/
@import "../variables";

.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
}
.site__body {
    flex-grow: 1;
    color: #000;
    // margin-top: 3%;
}
.unsite_body{
    margin-top: -3%;
}
.site__footer {
    flex-shrink: 0;
    color: #fff;
}
