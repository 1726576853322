/*
// .products-list
*/
@use "sass:math";
@import "../variables";
@import "../functions";
@import "../mixins/product-card";

@mixin local-layout($columns: 1, $margin-vertical: 0, $margin-horizontal: 0) {
    .products-list__body {
        margin: #{-$margin-vertical} #{-$margin-horizontal};
    }
    .products-list__item {
        @if ($columns == 1) {
            width: 100%;
        } @else {
            width: calc(
                #{math.div(100%, $columns)} - #{$margin-horizontal * 2}
            );
        }
        margin: $margin-vertical $margin-horizontal;
    }
}

.products-list__body {
    display: flex;
    flex-wrap: wrap;
}
.products-list__item {
    display: flex;

    .product-card {
        width: 100%;
        flex-shrink: 0;
    }
}

// Grid
.products-list[data-layout^="grid-"] {
    .product-card {
        @include product-card-grid();
    }

    &[data-with-features="true"] .product-card {
        @include product-card-grid-with-features();
    }
}
.products-list[data-layout="grid-3-sidebar"] {
    @include local-layout(3, 6px, 6px);

    .product-card {
        @include product-card-grid-nl();
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
    @media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
        @include local-layout(3, 6px, 6px);
    }
    @media (max-width: breakpoint(sm-end)) and (min-width: 480px) {
        @include local-layout(2, 6px, 6px);
    }
    @media (max-width: 479px) {
        @include local-layout(1, 6px, 6px);
    }
}
.products-list[data-layout="grid-4-full"] {
    @include local-layout(4, 6px, 6px);

    .product-card {
        @include product-card-grid-nl();
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
    @media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
        @include local-layout(3, 6px, 6px);
    }
    @media (max-width: breakpoint(sm-end)) and (min-width: 480px) {
        @include local-layout(2, 6px, 6px);
    }
    @media (max-width: 479px) {
        @include local-layout(1, 6px, 6px);
    }
}
.products-list[data-layout="grid-5-full"] {
    @include local-layout(5, 6px, 6px);

    @media (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }

    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)) {
        @include local-layout(4, 6px, 6px);
    }
    @media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
        @include local-layout(3, 6px, 6px);
    }
    @media (max-width: breakpoint(sm-end)) and (min-width: 480px) {
        @include local-layout(2, 6px, 6px);
    }
    @media (max-width: 479px) {
        @include local-layout(1, 6px, 6px);
    }
}

.products-list[data-layout="list"] {
    @include local-layout(1, 6px);

    .products-list__item {
        display: block;
    }

    .product-card {
        @include product-card-list();
    }
}

.product-card-lst {
    display: flex !important;
    flex-direction: column !important;
    margin-bottom: 25px;
    padding-bottom: 14px;
}
.calunder-data {
    /* background-color: #fff0f0; */
    text-align: center;
    /* border-radius: 9px; */
    width: 50%;
    /* margin-left: 20px; */
    margin-bottom: 15px;
    margin-top: 15px;
    line-height: 40px;
}
.month-txt {
    margin-top: 20px;
    /* margin-left: 20px; */
    font-size: 19px;
    font-weight: 600;
    width: 50%;
    text-align: center;
    display: block;
}
.date-bg {
    background-color: #c60800;
    margin-left: 20%;
    margin-right: 20%;
    padding: 15px 0px;
    padding-top: 29px;
    color: white;
    border-radius: 10px;
    box-shadow: 4px 6px 7px 0px rgb(76 76 76 / 20%);
}
.product-card-lst::before {
    border-radius: 10px !important;

    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}
.product-card-lst:hover {
    border-radius: 10px !important;
    box-shadow: 0px 4px 8px 0px rgba(248, 0, 0, 0.2);
}
.menu-txt {
    padding: 0px 0px;
    font-size: 25px;
    font-weight: 700;
    display: initial;
    padding-right: 4px;
    text-align: center;
}
